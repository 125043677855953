import {connect} from "react-redux";

import kywrapper from "../api/core/kywrapper";
import {injectIntl} from "./injectIntlOrPolyfillIntl";

import {appendError as appendErrorForMember} from "../actions";
import {appendError as appendErrorForStaff} from "../actions/staff";
import isStaff from "./isStaff";
import {changeCrossSuccessList} from "../actions";

const InjectKyWrapperCallback = ({intl, appendErrorForMember, appendErrorForStaff, changeCrossSuccessList}) => {
    const { formatMessage} = intl;

    const appendError = isStaff()
        ? appendErrorForStaff
        : appendErrorForMember;

    const showGlobalErrorWindow = data => {
        const message = data.message === 'Server Error'
            ? formatMessage({id:'validation.something_went_wrong'})
            : data.message;
        appendError(message);
    };

    kywrapper.catchCallback = (err) => {
        if (err?.response) {
            const { status } = err?.response;
            if ([401, 503, 400].indexOf(status) === -1) {
                switch (status) {
                    case 422:
                        err.response.json().then(data => {
                            switch (data.code) {
                                case 'LAND_ACTIVATION_REQUIREMENT':
                                    showGlobalErrorWindow({message: formatMessage({id: "MARKETPLACE.ERROR.LAND_ACTIVATION_REQUIREMENT"})})
                                    break;
                                default:
                                    break;
                            }
                        });
                        throw err;

                    case 406:
                        err.response.json().then(data => {
                            switch (data.code) {
                                case 'BALANCE_NOT_AVAILABLE':
                                case '2D_APPLICATION_ERROR':
                                case 'UNABLE_TO_CALCULATE_PRICE':
                                    console.error(data);
                                    break;
                                default:
                                    showGlobalErrorWindow(data);
                            }
                        });
                        break;
                    case 429:
                        err.response.json().then(data => {
                            switch (data?.code) {
                                case 'SCRAPING_LIMIT_REACHED':
                                    showGlobalErrorWindow({message: formatMessage({id: data.code})})
                                    break
                                default:
                                    data.message.indexOf("auth.throttle") === -1
                                        ? showGlobalErrorWindow(data)
                                        : showGlobalErrorWindow({message: formatMessage({id:'validation.auth.throttle_up'})});
                                    break;
                            }
                        })
                        break;
                    case 404:
                        err.response.json().then(data => {
                            switch (data.code) {
                                case 'AD_NOT_FOUND':
                                    console.error(data);
                                    break;
                                default:
                                    switch (data.message) {
                                        case 'Price not found':
                                            console.error(data);
                                            break;
                                        default:
                                            showGlobalErrorWindow(data);
                                    }
                            }

                        });
                        break;
                    case 403:
                        err.response.json().then(data => {
                            switch (data.code) {
                                case 'CANT_DELETE_LAST_AD':
                                    appendError(formatMessage({id:'validation.CANT_DELETE_LAST_AD'}));
                                    break;
                                case 'BUILDING_PLACED':
                                    console.error(data);
                                    break;
                                case 'ASSET_SHARED_NOW':
                                case 'SKIN_NOT_SHARABLE':
                                case 'ASSET_RENTED':
                                case 'NOT_ASSET_OWNER':
                                    changeCrossSuccessList([{failed: true, text: data.message}]);
                                    break;
                                case 'SAME_MEMBER_LIMIT':
                                    changeCrossSuccessList([{failed: true, text: formatMessage({id:'ASSET.SHARING_ERROR_SAME_MEMBER_LIMIT'})}]);
                                    break;
                                default:
                                    console.log(data.code);
                                    showGlobalErrorWindow(data);
                            }
                        });
                        break;
                    default:
                        err.response.json().then(showGlobalErrorWindow);
                        break;
                }
            } else {
                throw err;
            }
        } else {
            throw err;
        }
    };

    return null;
};

const InjectKyWrapperCallbackWithIntl = injectIntl(InjectKyWrapperCallback);

const InjectKyWrapperCallbackComponent = connect(
    state => ({
    }),
    dispatch => ({
        appendErrorForMember: (text) => {
            dispatch(appendErrorForMember(text));
        },
        appendErrorForStaff: (text) => {
            dispatch(appendErrorForStaff(text));
        },
        changeCrossSuccessList: (info) => {
            dispatch(changeCrossSuccessList(info));
        },
    })
)(InjectKyWrapperCallbackWithIntl);

export default InjectKyWrapperCallbackComponent;
