export const LOGIN_STAFF_PROCESS = 'LOGIN_STAFF_PROCESS';
export const SET_STAFF_ACCESS_TOKEN = 'SET_STAFF_ACCESS_TOKEN';
export const SET_STAFF_OTP = 'SET_STAFF_OTP';
export const SET_STAFF_EXPIRED_AT = 'SET_STAFF_EXPIRED_AT';
export const SET_STAFF_MEMBER_ID = 'SET_STAFF_MEMBER_ID';

export const SET_STAFF_MEMBER = 'SET_STAFF_MEMBER';
export const SET_STAFF_CLOSEST_EXPIRATION = 'SET_STAFF_CLOSEST_EXPIRATION';

export const SET_APP_TITLE = 'SET_APP_TITLE';

export const SET_SHOW_ERROR_MODAL = 'SET_SHOW_ERROR_MODAL';
export const APPEND_ERROR = 'APPEND_ERROR';
export const CHANGE_ERROR_LIST = 'CHANGE_ERROR_LIST';

export const setStaffLoginProcessGo = (isGo) => ({
    type: LOGIN_STAFF_PROCESS,
    payload:isGo,
});
export const setStaffMemberData = (memberData) => {
    localStorage.setItem('staffMember', JSON.stringify(memberData));
    return {
        type: SET_STAFF_MEMBER,
        payload:memberData,
    };
};
export const setStaffOtp = (otp) => ({
    type: SET_STAFF_OTP,
    payload:otp
});
export const setStaffAccessToken = (accessToken) => {
    localStorage.setItem('staffAccessToken', accessToken);
    return {
        type: SET_STAFF_ACCESS_TOKEN,
        payload:accessToken
    }
};
export const setStaffMemberId = (memberId) => ({
    type:SET_STAFF_MEMBER_ID,
    payload: memberId,
});
export const setStaffClosestExpiration = payload => ({
    type:SET_STAFF_CLOSEST_EXPIRATION,
    payload
});
export const setStaffExpiredAt = payload => {
    localStorage.setItem('staffExpiredAt', payload.getTime());
    return {
        type:SET_STAFF_EXPIRED_AT,
        payload
    }
};
export const setAppTitle = payload => ({
    type: SET_APP_TITLE,
    payload
});
export const setShowErrorModal = payload => ({
    type: SET_SHOW_ERROR_MODAL,
    payload,
});
export const appendError = payload => ({
    type: APPEND_ERROR,
    payload
});
export const changeErrorList = payload => ({
    type: CHANGE_ERROR_LIST,
    payload
});
