import {connect} from "react-redux";
import memberService from "../api/member";
import announcementsService from "../api/announcements";
import eventsService from "../api/events";
import walletAddress from "../api/walletAddress";
import goldrushService from "../api/goldrush";
import lumberyardService from "../api/lumberyard";
import adsService from "../api/ads";
import apiBuildings from "../api/buildings";
import {
    setClosestExpiration,
    setBalance,
    setTickets,
    setTicketsOpen,
    setWalletAddress,
    setWalletAddressStatus,
    setPoints,
    setUnreadAnnouncements,
    setUnreadEvents,
    setSysWalletAddress,
    setSysWalletBalance,
    setBalanceOrdinary,
    setBalanceItw,
    setBalanceBonus,
    setMemberMinerals,
    setMemberWood,
    setMemberIsHavePlots,
    setMemberAdnetIsVisible,
    set2DAccess,
} from "../actions";
import {useEffect, useMemo} from "react";
import { useParams } from "react-router-dom";
import {injectIntl} from "react-intl";
import {getMoneyValue} from "./utils";
import mapService from "../api/map";

const LoadCommonData = ({
    intl,
    children,
    logged, dataLoaded,
    setPoints,
    setClosestExpiration,
    setBalance,
    setBalanceOrdinary,
    setBalanceBonus,
    setMinerals,
    setTickets,
    setWalletAddress,
    setWalletAddressStatus,
    setUnreadAnnouncements,
    setUnreadEvents,
    setSysWalletAddress,
    setSysWalletBalance,
    setWood,
    setMemberIsHavePlots,
    memberHasPlots,
    memberAdnetIsVisible,
    setMemberAdnetIsVisible,
    memberId,
    set2DAccess,
}) => {
    const isLoader = window.location.pathname.indexOf('loader') !== -1;
    const { lang } = useParams();

    useEffect(() => {
        if (memberHasPlots === null && !!localStorage.getItem('member')) {
            mapService.myPlots(1, 1)
                .then(response => response.json())
                .then(({data}) => {
                    setMemberIsHavePlots(!!data.length)
                });
        }
    }, [memberHasPlots]);

    const _2DAccess = useMemo(() => {
        const accessString = process.env.REACT_APP_2D_ACCESS;
        if (accessString && memberId) {
            if (accessString === 'all') {
                return true
            } else {
                const accessArr = accessString.split(',').map(el => +el)
                return accessArr.includes(+memberId);
            }
        }
        return false
    }, [memberId])

    useEffect(() => {
        if (logged && !dataLoaded && !isLoader) {
            memberService.sysBalance()
                .then(response => response.json())
                .then((cmlxBalanceData) => {
                    setSysWalletBalance(getMoneyValue(cmlxBalanceData.balance));
                    setSysWalletAddress(cmlxBalanceData.address);
                }).catch(() => {
                    setSysWalletBalance(null);
                });
            memberService.ticketBalance()
                .then(response => response.json())
                .then((data) => {
                    setClosestExpiration(data.closestExpiredAt);
                    setTickets(data.total.decimal);
                    // AW-937
                    // if (data.openDate !== null) {
                    //     setTicketsOpen(formatMessage({id:'VALIDATION.PLOT_ACTIVATION_NOT_AVAILABLE_UNTIL'},{openDate: formatDate(toCSTTime(data.openDate))}));
                    // } else {
                    //     setTicketsOpen(null);
                    // }

                    memberService.cmlxBalance()
                        .then(response => response.json())
                        .then((cmlxBalanceData) => {
                            setBalance(getMoneyValue(cmlxBalanceData.balance));
                            setBalanceOrdinary(getMoneyValue(cmlxBalanceData.balanceOrdinary));
                            setBalanceBonus(getMoneyValue(cmlxBalanceData.balanceBonus));
                        }).catch(() => {
                            setBalance(null);
                            setBalanceOrdinary(null);
                            setBalanceBonus(null);
                        });

                    // if (itwIsAccese)
                    //     memberService.itwBalance()
                    //         .then(response => response.json())
                    //         .then((data) => {
                    //             setBalanceItw(getMoneyValue(data.balance));
                    //         }).catch(() => {
                    //             setBalanceItw(null);
                    //         });

                    memberService.points()
                        .then(response => response.json())
                        .then((pointBalances) => {
                            setPoints(getMoneyValue(pointBalances.amount))
                        });

                    walletAddress.address()
                        .then(response => response.json())
                        .then((walletData) => {
                            setWalletAddress(walletData.address);
                            setWalletAddressStatus(walletData.status);
                        });
                    if (!memberAdnetIsVisible) {
                        adsService.getAdNetVisibility()
                            .then((isVisible) => {
                                if (isVisible) {
                                    setMemberAdnetIsVisible(true);
                                }
                            });
                    }
                });

            Promise.all([
                announcementsService.unread(lang).then(response => response.json()),
                eventsService.unread(lang).then(response => response.json())
            ]).then(([announcementsUnread, eventsUnread]) => {
                setUnreadAnnouncements(announcementsUnread.count);
                setUnreadEvents(eventsUnread.count);
            });

            if (_2DAccess)
                Promise.all([
                    apiBuildings
                        .owned({
                            page: 1,
                            perPage: 1,
                        })
                        .then(response => response.json()),
                    adsService.credits().then(response => response.json())
                ]).then(([buildings, adsCredits]) => {
                    set2DAccess(!(!buildings.data.length && !adsCredits.balance));
                });

            goldrushService.balances()
                .then(response => response.json())
                .then(response => {
                    setMinerals(response);
                });
            lumberyardService.materials()
                .then(response => response.json())
                .then(response => {
                    setWood(response);
                });
        }
    });

    return children;
};

export default connect(
    (state) => ({
        logged: !!state.member.id,
        dataLoaded: state.member.balance !== undefined
            && state.member.tickets !== undefined
            && state.member.closestExpiration !== undefined
            && state.member.minerals !== undefined
            && state.member.wood !== undefined,
        itwIsAccese: state.member.accessItw,
        memberHasPlots: state.member.memberHasPlots,
        memberAdnetIsVisible: state.member.adnetIsVisible,
        memberId: state.member.id,
    }),
    (dispatch) => ({
        setClosestExpiration: (closestExpiration) => {
            dispatch(setClosestExpiration(closestExpiration));
        },
        setBalance: (balance) => {
            dispatch(setBalance(balance));
        },
        setBalanceOrdinary: (balance) => {
            dispatch(setBalanceOrdinary(balance));
        },
        setBalanceBonus: (balance) => {
            dispatch(setBalanceBonus(balance));
        },
        setBalanceItw: (balance) => {
            dispatch(setBalanceItw(balance));
        },
        setTickets: (tickets) => {
            dispatch(setTickets(tickets));
        },
        setWood: (tickets) => {
            dispatch(setMemberWood(tickets));
        },
        setMinerals: (tickets) => {
            dispatch(setMemberMinerals(tickets));
        },
        setTicketsOpen: (val) => {
            dispatch(setTicketsOpen(val));
        },
        setPoints: (points) => {
            dispatch(setPoints(points));
        },
        setWalletAddress: (addressStatus) => {
            dispatch(setWalletAddress(addressStatus));
        },
        setWalletAddressStatus: (addressStatus) => {
            dispatch(setWalletAddressStatus(addressStatus));
        },
        setUnreadAnnouncements: (unread) => {
            dispatch(setUnreadAnnouncements(unread));
        },
        setUnreadEvents: (unread) => {
            dispatch(setUnreadEvents(unread));
        },
        setSysWalletBalance: (unread) => {
            dispatch(setSysWalletBalance(unread));
        },
        setSysWalletAddress: (unread) => {
            dispatch(setSysWalletAddress(unread));
        },
        setMemberIsHavePlots: (value) => {
            dispatch(setMemberIsHavePlots(value));
        },
        setMemberAdnetIsVisible: (value) => {
            dispatch(setMemberAdnetIsVisible(value));
        },
        set2DAccess: (value) => {
            dispatch(set2DAccess(value));
        },
    }),
)(injectIntl(LoadCommonData));

