import {
    ACTIVATE_PROMT,
    COMPLITE_PROMT,
    ADD_PROMT_ELEMENT,
    DEL_PROMT_ELEMENT,
    SKIP_ALL_PROMT
} from '../actions';

const initialState = {
    currentPromt: null,
    complitedPromt: {skipAll: true}, // AW-937
    elementsList: new Map(),
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPLITE_PROMT:
            state.complitedPromt[action.payload] = true;
            localStorage.setItem('promtState', JSON.stringify(state.complitedPromt));
            return {...state, currentPromt: null};
        case ACTIVATE_PROMT:
            return {...state, currentPromt: state.complitedPromt[action.payload] ? null : action.payload};
        case ADD_PROMT_ELEMENT:
            if(!state.complitedPromt[action.payload.promtName]) {
                !state.elementsList.has(action.payload.promtName) && state.elementsList.set(action.payload.promtName, [])
                let addElements = state.elementsList.get(action.payload.promtName);
                addElements.push(action.payload);
            }
            return {...state};
        case DEL_PROMT_ELEMENT:
            if (!state.elementsList.has(action.payload.promtName)) return;
            let delElements = state.elementsList.get(action.payload.promtName);
            delElements.filter(el => el.element !== action.payload.element)
            return {...state};
        case SKIP_ALL_PROMT:
                state.complitedPromt = {skipAll: true};
                localStorage.setItem('promtState', JSON.stringify(state.complitedPromt));
                return {...state, currentPromt: null};
        default:
            return state;
    }
};
