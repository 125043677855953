import { Modal } from 'react-bootstrap';

import mapImg from '../assets/interworld_map.png';

const Map = (props) => {
    const { onHide } = props;
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"modal__map"}
        >
            <button type="button" onClick={onHide} className="btn-close" aria-label="Close"></button>
            <Modal.Body>
                <img src={mapImg} className={"img__map"} alt="" style={{"width":"fit-content"}}/>
            </Modal.Body>
        </Modal>
    );
};

export default Map;
