import {
    SET_MARKETPLACE_SELECTED_ITEM,
    SET_MARKETPLACE_CONTENT_TYPE,
    SET_MARKETPLACE_DEFAULT,
    SET_MARKETPLACE_ERROR
} from '../actions';

const initialState = {
    contentType: 'buy-minerals', 
    selectedItem: null,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MARKETPLACE_SELECTED_ITEM:
            return {...state, selectedItem:action.payload};
        case SET_MARKETPLACE_CONTENT_TYPE:
            localStorage.setItem('marketplace-type', action.payload);
            return {...state, contentType:action.payload, selectedItem: null};
        case SET_MARKETPLACE_ERROR:
            return {...state, error:action.payload};
        case SET_MARKETPLACE_DEFAULT:
            return initialState;
        default:
            return state;
    }
};
