import isStaff from '../../services/isStaff';

const makeTokenName = () => {
    if (isStaff()) {
        return 'staffAccessToken'
    } else {
        return 'accessToken';
    }
};

export default makeTokenName;
