export const MiniLoading = () => {
  return (
    <div className="lds-default__wrap"><div className="lds-default">{new Array(12).fill(null).map((_, index) => <div key={index} />)}</div></div>
  );
}

export const EmptyLoader = () => {
  return (
    <div></div>
  );
}

export default EmptyLoader;