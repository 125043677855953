/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { enableMapSet, setAutoFreeze } from "immer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import {
    // BrowserRouter as Router,
    Routes,
    Route,
    HashRouter as Router,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import {applyMiddleware, createStore} from "redux";
import reducers from "reducers";
import { hydrate, render } from "react-dom";

import Root from "routes/root/Root";

import MiniLoading from "components/MiniLoading";

import 'styles/pages/_main.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const About = lazy(() => import("./routes/about/About"));

enableMapSet();
setAutoFreeze(false);

const middleware = [
    // logger,
];
const store = createStore(reducers, applyMiddleware(...middleware));
const rootElement = document.getElementById("root");

const defaultFallback = <MiniLoading />;

const loadWrapper = (component, fallback = defaultFallback) => {
  return <Suspense fallback={fallback}>
    {component}
  </Suspense>
}

const App = () => {
    return <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/:lang" element={<Root key={"main"} />}/>
                    <Route path="/:lang/about" element={loadWrapper(<About />)} />
                    <Route path="*" element={<Navigate to={"/en"} />} />
                </Routes>
            </Router>
        </Provider>
    </React.StrictMode>;
}

if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}


reportWebVitals();
