import {
    LOAD_NEXT_TABLE_PAGE,
    RETURN_TO_TABLE_PAGE,
} from '../actions';

const initialState = {
    tableId: '',
    page: 0,
    isReturned: false,
    filters: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_NEXT_TABLE_PAGE:
            return {...state, tableId: action.payload.tableId, page: action.payload.page, filters: action.payload.filters, isReturned: false};
        case RETURN_TO_TABLE_PAGE:
            if (action.payload === state.tableId)
                return {...state, isReturned: true};
            else 
                return {...initialState};
        default:
            return state;
    }
}
