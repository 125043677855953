import stylesD from "../assets/styles/three.module.scss";
import stylesM from "../assets/styles/mobile/three.module.scss";
import { injectIntl } from 'react-intl';

import { useMemo } from 'react';
import { connect } from 'react-redux';

import imgArrow from "../assets/arrow-right-up-grn.svg";

const Item = ({number, colorRect, title, text, styles}) => {
  return <div className={styles.item}>
    <div className={styles.item_left}>
      <div className={styles.item_number} style={{'--color-rect': colorRect}}>
        <span>{number}</span>
      </div>
    </div>
    <div className={styles.item_right}>
      <div className={styles.item_title}>
        {title}
      </div>
      <div className={styles.item_text}>
        {text}
      </div>
  </div>
  </div>
}

export const Three = ({intl, mobile}) => {
  const {formatMessage} = intl;
  const styles = useMemo(() => mobile ? stylesM : stylesD, [mobile]);
  return (
    <div className={styles.three}>
      <div className={styles.title}>
        <img src={imgArrow} alt="" />
        {mobile ? <br/> : null}
        {/* <span className='gray'>To make the world better</span><br/>
        <span>by bringing synergies of</span><br/>
        <span className='pink'>people</span> and <span className='blue'>computing powers</span> */}
        <span dangerouslySetInnerHTML={{__html: formatMessage({'id': "LANDING.ALLOW"})}}></span>
      </div>
      <Item
        styles={styles}
        number={'01'}
        colorRect={'#DE3FEC'}
        title={formatMessage({'id':"LANDING.CITIZENSHIP"})}
        text={formatMessage({'id':"LANDING.CITIZENSHIP_DESC"})}
      />
      <Item
        styles={styles}
        number={'02'}
        colorRect={'#3F50EC'}
        title={formatMessage({'id':"LANDING.CREATE_YOUR_OWN_VIRTUAL_EXPERIENCE"})}
        text={formatMessage({'id':"LANDING.CREATE_YOUR_OWN_VIRTUAL_EXPERIENCE_DESC"})}
      />
      <Item
        styles={styles}
        number={'03'}
        colorRect={'#BCFC37'}
        title={formatMessage({'id':"LANDING.SOCIAL_EXPERIENCE"})}
        text={formatMessage({'id':"LANDING.SOCIAL_EXPERIENCE_DESC"})}
      />
    </div>
  );
};

export default connect(
  (state)=>({
      mobile: state.common.mobile,
  }),
  ()=>({

  })
)(injectIntl(Three));
