import {useMemo, useState} from "react";
import { useLocation, useParams } from "react-router-dom";

const MobileMenuSection = ({label, sectionClass, onLabelClick, children, activeRoutes = []}) => {
    const location = useLocation();
    const { lang } = useParams();
    const pathname = useMemo(() => location.pathname.replace(`/${lang}/`, '').replace(`/${lang}`, ''), [lang, location]);

    const [showList, setShowList] = useState(false);
    const withSection = !onLabelClick;
    const isActiveRoute = activeRoutes.indexOf(pathname) !== -1;

    const sectionClasses = [
        'mobile-menu--section',
        sectionClass ?? '',
    ];
    const titleClasses = [
        'mobile-menu--h2',
        showList ? 'open-submenu' : '',
        isActiveRoute ? 'active-submenu' : '',
    ];
    const listClasses = [
        'mobile-menu--list',
        showList ? 'd-flex':'d-none',
    ];

    const onToggleList = () => setShowList(!showList);
    onLabelClick = onLabelClick || onToggleList;

    return (
        <div className={sectionClasses.join(' ')}>
            <div className={titleClasses.join(' ')} onClick={onLabelClick}>
                <div className="d-flex justify-content-between align-items-center">
                    <div>{label}</div>
                    {withSection && <>
                        {showList
                            ? <i className="faw-vector_down" />
                            : <i className="faw-vector_right" />
                        }
                    </>}
                    {/* {
                        withSection && <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" style={{'transition':'transform .125s','transform':(showList ? 'rotate(90deg)':'')}}>
                            <path d="M1.66668 0L0.726685 0.94L3.78002 4L0.726685 7.06L1.66668 8L5.66668 4L1.66668 0Z" fill={!showList ? "#2E3549":"#994401"} />
                        </svg>
                    } */}

                </div>
            </div>
            <div className={listClasses.join(' ')}>
                {children}
            </div>
        </div>
    )
};

export default MobileMenuSection;
