import api from './core/api';
import qs from "qs";

class Events {
    constructor(api) {
        this.api = api;
    }
    list({
        page = 1,
        perPage = 6,
        // direction = 'desc',
        lang = 'en',
        // sort='id'
    }) {
        const searchParams = { page, perPage, lang };
        return this.api.client.get(
            this.api.getUrl('member/event/list'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }

    single({
        id,
        lang = 'en'
    }) {
        const searchParams = { eventId: id, lang };
        return this.api.client.get(
            this.api.getUrl('member/event'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }
    unread(lang) {
        const searchParams = { lang };
        return this.api.client.get(
            this.api.getUrl('member/event/unread'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }
}

export default (new Events(api));
