import React from "react";
import {injectIntl as nativeInjectIntl} from "react-intl";
import isStaff from './isStaff';
import messagesEN from '../lang/en.json';

const formatMessage = (v, dict) => {
    let message = messagesEN[v.id] ?? v.id;
    if (dict) {
        Object.keys(dict).forEach(function (key, index) {
            message = message.replace(`{${key}}`, dict[key]);
        });
    }
    return message;
};

export const injectIntl = (Component) => {
    if (isStaff()) {
        const intl = {formatMessage};
        return (props) => (<Component {...props} intl={intl} />);
    }
    return nativeInjectIntl(Component);
};
