import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import stylesD from "../assets/styles/second.module.scss";
import stylesM from "../assets/styles/mobile/second.module.scss";
import imgHomes from "../assets/homes.png";
import { useMemo } from 'react';

export const Second = ({intl, mobile, ...props }) => {
  const { formatMessage } = intl;
  
  const styles = useMemo(() => mobile ? stylesM : stylesD, [mobile]);
  return (
    <div className={styles["second"]}>
      <div className={styles["next-gen"]}>
        {formatMessage({'id':"LANDING.NEXT_GEN"})}
      </div>

      <div
        className={
          styles[
            "next-gen-text"
          ]
        }
      >{formatMessage({'id':"LANDING.WHATISIT_DESC"})}</div>

      <div className={styles["img"]}>
        <img className={styles["_1"]} src={imgHomes} alt=''/>
      </div>
    </div>
  );
};

export default connect(
  (state)=>({
      mobile: state.common.mobile,
  }),
  ()=>({

  })
)(injectIntl(Second));
