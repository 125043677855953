import {
    SET_MOBILE,
    SET_OPEN_SUBMENU,
    SET_SHOW_MODAL_ABEY_WALLET,
    SET_SHOW_MODAL_LIKED_WALLET,
    SET_IS_SHORT_MENU,
  
} from '../actions';

const initialState = {
    mobile: null,
    isProd: window.location.host === "www.abeyworld.com" || window.location.host === "abeyworld.com" || window.location.host === "www.stage.abeyworld.com" || window.location.host === "stage.abeyworld.com",
    openSubmenu: '',
    showModalAbeyWallet: false,
    showModalLinkedWalet: false,
    isShortMenu: false,
    appTitle: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MOBILE:
            return {...state, mobile: action.payload}
        case SET_OPEN_SUBMENU:
            return {...state, openSubmenu: action.payload}
        case SET_SHOW_MODAL_ABEY_WALLET:
            return {...state, showModalAbeyWallet: action.payload}
        case SET_SHOW_MODAL_LIKED_WALLET:
            return {...state, showModalLinkedWalet: action.payload}
        case SET_IS_SHORT_MENU:
            return {...state, isShortMenu: action.payload}
        default:
            return state;
    }
};
