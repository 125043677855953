import {connect} from "react-redux";
import {useEffect, useRef, useCallback} from "react";
import {isMobile} from 'react-device-detect';
import {setMobile} from "../actions";

const Mobile = ({ mobile, setMobile, children }) => {
  const metaViewport = useRef(document.querySelector("meta[name=viewport]"));
  const body = useRef(document.querySelector("body"));

  const setParams = useCallback((mobile) => {
    setMobile(mobile);
    metaViewport.current.setAttribute('content', mobile ? 'width=device-width, initial-scale=1' : 'width=1600');
    if (mobile) {
      body.current.setAttribute('class', 'mobile');
    } else {
      body.current.removeAttribute('class');
    }
  }, [setMobile])

  useEffect(() => {
    setParams(isMobile);
  }, [isMobile])

  return <>{(mobile !== null) && children}</>
}

export default connect(
  (state) => ({
      mobile: state.common.mobile,
  }),
  (dispatch) => ({
      setMobile: (val) => {
          dispatch(setMobile(val));
      },
  }),
)(Mobile)
