import {
    SET_MEMBER,
    SET_CLOSEST_EXPIRATION,
    SET_BALANCE,
    SET_BALANCE_ORDINARY,
    SET_BALANCE_BONUS,
    SET_BALANCE_ITW,
    SET_TICKETS,
    SET_MEMBER_MINERALS,
    SET_MEMBER_WOOD,
    SET_TICKETS_OPEN,
    SET_WALLET_ADDRESS,
    SET_WALLET_ADDRESS_STATUS,
    SET_POINTS,
    SET_UNREAD_EVENTS,
    SET_UNREAD_ANNOUNCEMENTS,
    SET_SYS_WALLET_ADDRESS,
    SET_SYS_WALLET_BALANCE,
    SET_MEMBER_IS_HAVE_PLOTS,
    SET_MEMBER_ADNET_IS_VISIBLE,
    SET_2D_ACCESS,
} from "../actions";

const getAccess = (member, accessString) => {
    if (accessString && member?.id) {
        if (accessString === 'all') {
            return true
        } else {
            const accessArr = accessString.split(',').map(el => +el)
            return accessArr.includes(+member.id);
        }
    }
    return accessString === undefined;
};

let initialState = {
    id: 0,
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    address:'',
    addressStatus:'',
    sysWalletAddress: '',
    ownerId:'',
    openDate:'',
    createdAt:'',
    updatedAt:'',
    adnetIsVisible: false,
    _2DAccess: false,
};
if (localStorage.getItem('member')) {
    initialState = JSON.parse(localStorage.getItem('member'));
}
const adnetIsVisibleKey = `adnetIsVisible_${initialState.id}`;
if (initialState.id && sessionStorage.getItem(adnetIsVisibleKey)) {
    initialState.adnetIsVisible = JSON.parse(sessionStorage.getItem(adnetIsVisibleKey) || false);
}
initialState = {
    ...initialState,
    addressStatus:null,
    closestExpiration:'',
    sysWalletBalance: undefined,
    balance: undefined,
    balanceOrdinary: undefined,
    balanceBonus: undefined,
    balanceItw: undefined,
    accessItw:  getAccess(initialState, process.env.REACT_APP_ITW_ALLOWED_MEMBER),
    points: undefined,
    tickets: undefined,
    minerals: undefined,
    wood: undefined,
    unreadAnnouncements: 0,
    unreadEvents: 0,
    accessTickets:  getAccess(initialState, process.env.REACT_APP_TICKETS_ALLOWED_MEMBER),
    accessLive2Earn: false, // AW-937 getAccess(initialState, process.env.REACT_APP_LIVE_2_EARN_ALLOWED_MEMBER),
    memberHasPlots: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case  SET_MEMBER:
            return {...action.payload,
                accessTickets:  getAccess(action.payload, process.env.REACT_APP_TICKETS_ALLOWED_MEMBER),
                accessLive2Earn:  false,  // getAccess(action.payload, process.env.REACT_APP_LIVE_2_EARN_ALLOWED_MEMBER),
                accessItw:  getAccess(initialState, process.env.REACT_APP_ITW_ALLOWED_MEMBER),
            };
        case  SET_CLOSEST_EXPIRATION:
            return {...state, closestExpiration:action.payload};
        case  SET_BALANCE:
            return {...state, balance:action.payload};
        case  SET_BALANCE_ORDINARY:
            return {...state, balanceOrdinary:action.payload};
        case  SET_BALANCE_BONUS:
            return {...state, balanceBonus:action.payload};
        case  SET_BALANCE_ITW:
            return {...state, balanceItw:action.payload};
        case  SET_TICKETS_OPEN:
            return {...state, openDate: action.payload};
        case  SET_TICKETS:
            return {...state, tickets:action.payload};
        case  SET_MEMBER_MINERALS:
            return {...state, minerals:action.payload};
        case  SET_MEMBER_WOOD:
            return {...state, wood:action.payload};
        case  SET_SYS_WALLET_ADDRESS:
            return {...state, sysWalletAddress:action.payload};
        case  SET_SYS_WALLET_BALANCE:
            return {...state, sysWalletBalance:action.payload};
        case  SET_WALLET_ADDRESS:
            return {...state, address:action.payload};
        case  SET_WALLET_ADDRESS_STATUS:
            return {...state, addressStatus:action.payload};
        case  SET_POINTS:
            return {...state, points:action.payload};
        case  SET_UNREAD_ANNOUNCEMENTS:
            return {...state, unreadAnnouncements:action.payload};
        case  SET_UNREAD_EVENTS:
            return {...state, unreadEvents:action.payload};
        case  SET_MEMBER_IS_HAVE_PLOTS:
            return {...state, memberHasPlots:action.payload};
        case SET_MEMBER_ADNET_IS_VISIBLE:
            sessionStorage.setItem(adnetIsVisibleKey, action.payload);
            return {...state, adnetIsVisible:action.payload};
        case SET_2D_ACCESS:
            return {...state, _2DAccess:action.payload};
        default:
            return state;
    }
};
