import { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import styles from '../assets/styles/styles.module.scss';

const Dropdown = ({ options, selectedOption, onOptionSelect, children}) => {
  const { lang } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const postfix = window.location.pathname.replace(`/${lang}`, '');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleOptionClick = (option) => {
    if(onOptionSelect) onOptionSelect(option);
    setIsOpen(false);
    navigate(`${option}${postfix}`);
  };

  return (
    <div className={cn(styles["dropdown"])} ref={dropdownRef}>
      <div className={cn(styles["dropdown-toggle"])} onClick={() => setIsOpen(!isOpen)}>
        {children}
      </div>
      {isOpen && (
        <div className={cn(styles["dropdown-menu"])}>
          {options.map(({href, title}) => (
            <div
              key={href}
              className={cn(styles["dropdown-item"], {'active': lang === href.replace('/', '')})}
              onClick={() => handleOptionClick(href)}
            >
              {title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
