import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useState, useMemo } from 'react';

import Portal from "../../../../components/Portal";
import MapLand from "../../../../components/MapLand";

import cn from "classnames";
import stylesD from "../assets/styles/footer.module.scss";
import stylesM from "../assets/styles/mobile/footer.module.scss";

export const Footer = ({intl, mobile}) => {
  const styles = useMemo(() => mobile ? stylesM : stylesD, [mobile]);

  const { lang } = useParams();
  const {formatMessage} = intl;
  const postfix = window.location.pathname.replace(`/${lang}/`, '');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleIsActive = (check) => {
    return {[styles.active]: postfix === check}
  };

  const year = (new Date()).getFullYear();
  return (
    <div className={styles["footer"]}>
      <div className={styles["footer__menu"]}>
        <Link className={cn(styles["footer__item"], {[styles.active]: postfix === `/${lang}`}) } to={`/${lang}/index.html`}>{formatMessage({'id': 'LINK.HOME'})}</Link>
        <Link className={cn(styles["footer__item"], handleIsActive('about'))} to={`/${lang}/about/index.html`}>{formatMessage({'id': 'LINK.ABOUT'})}</Link>
        <span style={{cursor: 'pointer'}} className={cn(styles["footer__item"])} onClick={handleShow}>{formatMessage({'id': 'LINK.MAP'})}</span>
        {/* {process.env.REACT_APP_DAO_LINK === 'enabled' && <Link className={cn(styles["footer__item"], handleIsActive('dao'))} to={`/${lang}/dao`}>{formatMessage({'id': 'LINK.DAO'})}</Link>} */}
        {/*<Link className={cn(styles["footer__item"], {[styles.active]: postfix === `ads-info`})} to={`/${lang}/partners`}>{formatMessage({'id': 'LINK.PARTNERS'})}</Link>*/}
        {/*<Link className={cn(styles["footer__item"], handleIsActive('marketplace-info'))} to={`/${lang}/marketplace-info`}>{formatMessage({'id': 'LINK.MARKETPLACE'})}</Link>*/}
        {/* <Link className={cn(styles["footer__item"], handleIsActive('public-announcements'))} to={`/${lang}/public-announcements`}>{formatMessage({'id': 'HEADER.ANNOUNCEMENTS'})}</Link> */}
      </div>
      <div className={styles["footer__bottom"]}>
        <div className={styles["footer__address"]}>
          {process.env.REACT_APP_COMPANY_ADDRESS || ''}
        </div>
        <div className={styles["footer__copyright"]}>
          {formatMessage({id:"LANDING.FOOTER_TITLE"}, {year: year})}
        </div>
      </div>
      <Portal wrapperId='footer-modal'>
        <MapLand {...{show, onHide:handleClose}}/>
      </Portal>
    </div>
  );
};

export default connect(
  (state)=>({
    mobile: state.common.mobile,
  }),
  ()=>({

  })
)(injectIntl(Footer));
