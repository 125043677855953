/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useState, useMemo } from 'react';
import cn from "classnames";
import stylesD from "../assets/styles/top.module.scss";
import stylesM from "../assets/styles/mobile/top.module.scss";
import Dropdown from './Dropdown';
import { menuLang } from '../../../../services/language';
import Portal from "../../../../components/Portal";
import MapLand from "../../../../components/MapLand";

import imgLogo from "../assets/old_logo.svg";
import imgLogoDark from "../assets/old_logo_black.svg";
import imgIconDown from "../assets/down.svg";

const NavBar = ({intl, mobile, logged, className, dark = false}) => {
  const styles = useMemo(() => mobile ? stylesM : stylesD, [mobile]);

  const { lang } = useParams();
  const {formatMessage} = intl;
  const postfix = window.location.pathname.replace(`/${lang}/`, '');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleIsActive = (check) => {
    return {[styles.active]: postfix === check}
  };
  const showLoginLinks = !logged;

  return (<>
<div className={cn(styles.landing__menu__wrap, className, {dark})}>
            <div className={cn(styles["menu"])}>
                <Link to={`/${lang}`}>
                  <img
                    width="225"
                    height="56"
                    src={dark ? imgLogoDark : imgLogo} alt="InterWorld" className={cn(styles["pasted-20230710-142852-1-1"])}/>
                </Link>

                <div className={cn(styles["nav_container"])}>
                  <Link className={cn(styles["item"], {[styles.active]: postfix === `/${lang}`})} to={`/${lang}`}>{formatMessage({'id': 'LINK.HOME'})}</Link>
                  {/* <Link className={cn(styles["item"], handleIsActive('downloads'))} to={`/${lang}/downloads`}>{formatMessage({'id': 'LINK.DOWNLOADS'})}</Link> */}
                  <Link className={cn(styles["item"], handleIsActive('about'))} to={`/${lang}/about`}>{formatMessage({'id': 'LINK.ABOUT'})}</Link>
                  <span style={{cursor: 'pointer'}} className={cn(styles["item"])} onClick={handleShow}>{formatMessage({'id': 'LINK.MAP'})}</span>
                  {/* {process.env.REACT_APP_DAO_LINK === 'enabled' && <Link className={cn(styles["item"], handleIsActive('dao'))} to={`/${lang}/dao`}>{formatMessage({'id': 'LINK.DAO'})}</Link>} */}
                  {/*<Link className={cn(styles["item"], {[styles.active]: postfix === `ads-info`})} to={`/${lang}/partners`}>{formatMessage({'id': 'LINK.PARTNERS'})}</Link>*/}
                  {/* <Link className={cn(styles["item"], handleIsActive('marketplace-info'))} to={`/${lang}/marketplace-info`}>{formatMessage({'id': 'LINK.MARKETPLACE'})}</Link> */}
                  {/* <Link className={cn(styles["item"], handleIsActive('public-announcements'))} to={`/${lang}/public-announcements`}>{formatMessage({'id': 'HEADER.ANNOUNCEMENTS'})}</Link> */}

                  <div className={cn(styles["desk-header-right-side"])}>
                    {/* {showLoginLinks && <>
                      <Link className={cn(styles["button-small"], handleIsActive('login'), {[styles.dark]: dark})} to={`/${lang}/login`}>
                        <div className={cn(styles["log-in"])}>{formatMessage({'id': 'LINK.LOGIN'})}</div>
                      </Link>

                      <Link className={cn(styles["sign-up"], handleIsActive('registration'))} to={`/${lang}/registration`}>{formatMessage({'id': 'LOGIN.SIGN_UP'})}</Link>
                    </>} */}

                    <Dropdown options={menuLang.map(({href, label}) => ({href, title: label}))}>
                      <div className={cn(styles["language"])}>
                        <div className={cn(styles["en"])}>{menuLang.find((item) => item.href === `/${lang}` )?.href?.replace('/', '')}</div>
                        <img src={imgIconDown}
                          className={cn(styles["icon-down"])}
                          width="12"
                          height="12"
                          alt="" />
                      </div>
                    </Dropdown>
                  </div>
                </div>
            </div>
          </div>
          <Portal wrapperId='top-modal'>
              <MapLand {...{show, onHide:handleClose}}/>
            </Portal>
  </>)
}

export default connect(
  (state)=>({
    logged: !!state.member.id,
    mobile: state.common.mobile,
  }),
  ()=>({

  })
)(injectIntl(NavBar));
