import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { checkConfirmedLang, langToLocale, getMessages } from "./language";
import {useParams, Navigate} from "react-router-dom";
import api from '../api/core/api';

const InjectIntlProvider = (props) => {
    const { lang } = useParams();
    const isConfirmedLang = checkConfirmedLang(lang);
    localStorage.setItem('Accept-Language', lang);
    api.setLang(lang);
    const [messages, setMessages] = useState(null);
    useEffect(() => {
        if (isConfirmedLang) {
            getMessages(lang).then(res => {
                setMessages(res);
            });
        }
    }, [lang]);
    return (
        isConfirmedLang
            ? (messages && <IntlProvider lang={lang} locale={langToLocale(lang)} messages={messages}>
                {props.children}
            </IntlProvider>)
            : <Navigate to={'/en'} />
    );
};
export default InjectIntlProvider;

