import InjectIntlProvider from "./InjectIntlProvider";
import DocTitleUpdate from "./DocTitleUpdate";
import LoadCommonData from "./LoadCommonData"
import AuthChecker from "./AuthChecker";
import ClickerCatcher from "./CommonHandler";
import PromtsManagment from "./PromtsManagment";
import InjectKyWrapperCallback from "./InjectKyWrapperCallback";

import Mobile from "../services/Mobile";

const Middleware = ({children}) => {

    return (
        <InjectIntlProvider>
            <AuthChecker>
                <InjectKyWrapperCallback />
                <LoadCommonData>
                    <Mobile>
                        <PromtsManagment>
                            <DocTitleUpdate >
                                <ClickerCatcher>
                                    {children}
                                </ClickerCatcher>
                            </DocTitleUpdate>
                        </PromtsManagment>
                    </Mobile>
                </LoadCommonData>
            </AuthChecker>
        </InjectIntlProvider>
    );
};

export default Middleware
