import {
    SET_LANDS, SET_LANDS_LOADING,
    SET_MINERALS, SET_MINERALS_LOADING,
    SET_MINING,
    SET_SHOVEL, SET_SHOVEL_LOADING, SET_BUY_SHOVEL_LOADING,
    SET_AWARDS,
    SET_REDEEM_LOADING, SET_REDEEM_CALCULATE_LOADING,
    SET_GOLDRUSH_MAINSTATE, SET_GR_SELECTED_PLOT
} from '../actions';

const initialState = {
    mainState: 'default',
    lands: [],
    selectedPlot: {},
    landsLoading: true,
    minerals: [],
    mineralsLoading:true,
    shovel: {},
    shovelLoading: true,
    buyShovelLoading: false,
    mining: {},
    awards: {},
    redeemLoading: false,
    redeemCalculateLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LANDS:
            return {...state, lands:action.payload};
        case SET_LANDS_LOADING:
            return {...state, landsLoading:action.payload};
        case SET_MINERALS:
            return {...state, minerals:action.payload};
        case SET_MINERALS_LOADING:
            return {...state, mineralsLoading:action.payload};
        case SET_SHOVEL:
            return {...state, shovel:action.payload};
        case SET_SHOVEL_LOADING:
            return {...state, shovelLoading:action.payload};
        case SET_BUY_SHOVEL_LOADING:
            return {...state, buyShovelLoading:action.payload};
        case SET_MINING:
            return {...state, mining:action.payload};
        case SET_AWARDS:
            return {...state, awards:action.payload};
        case SET_REDEEM_CALCULATE_LOADING:
            return {...state, redeemCalculateLoading:action.payload};
        case SET_REDEEM_LOADING:
            return {...state, redeemLoading:action.payload};
        case SET_GOLDRUSH_MAINSTATE:
            return {...state, mainState:action.payload};
        case SET_GR_SELECTED_PLOT:
            return {...state, selectedPlot:action.payload};
        default:
            return state;
    }
};
