import {
    SET_SHOW_CROSS_SUCCESS_MODAL,
    APPEND_CROSS_SUCCESS, 
    CHANGE_CROSS_SUCCESS_LIST,
} from '../actions';

const initialState = {
    showSuccessModal: false,
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOW_CROSS_SUCCESS_MODAL:
            return {...state, showSuccessModal: action.payload}
        case APPEND_CROSS_SUCCESS:
            let {list} = state;
            list.push(action.payload);
            list = list.filter((v, i, s) => s.indexOf(v) === i)
            return {...state, list, showSuccessModal: true}
        case CHANGE_CROSS_SUCCESS_LIST:
            return {...state, list:action.payload, showSuccessModal: !!action.payload?.length}
        default:
            return state;
    }
};
