
// AW-937
// To delete need to find all comments "// AW-937" and delete all crutches
const AW937HideElementsForCondition = ({children, hide = true}) => {
    if (hide) {
        return null;
    }
    return (children);
};
export default AW937HideElementsForCondition;
