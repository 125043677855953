/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useState, useMemo, useEffect } from 'react';
import cn from "classnames";
import stylesD from "../assets/styles/top.module.scss";
import stylesM from "../assets/styles/mobile/top.module.scss";
import Portal from "../../../../components/Portal";
import MapLand from "../../../../components/MapLand";
import NavBar from './NavBar';

import MobileHeader from '../../landing/MobileHeader';

import imgCityPng from "../assets/city.png";
import imgCityWebp from "../assets/city.webp";
import imgEllipse5 from "../assets/ellipse_5.svg";
import imgWindows from "../assets/windows.svg";

import imgMember1 from "../assets/unsplash_XttWKETqCCQ.png";
import imgMember2 from "../assets/unsplash_QDq3YliZg48.png";
import imgMember3 from "../assets/unsplash_lkMJcGDZLVs.png";
import imgMember4 from "../assets/unsplash_HD8KlyWRYYM.png";

import imgIconArrowRightUp from "../assets/arrow-right-up.svg";
import { BASE_DOMAIN } from "etc/define";

const TopBanner = ({intl, mobile, logged, isShort=false}) => {
  const styles = useMemo(() => mobile ? stylesM : stylesD, [mobile]);

  useEffect(() => {
    if (mobile) {
      setTimeout(()=> {document.querySelector("body")
        .setAttribute('class', 'mobile overflow-auto');})
    }
  }, [mobile]);

  const { lang } = useParams();
  const {formatMessage} = intl;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const menuCallbacks = {
    'map':handleShow,
  };
    return (<>

            {!mobile && <div className={cn(styles.top_bg)}></div>}
            <div className={cn(styles.top)}>
              <div className={cn(styles.blur)}>
                <div className={cn(styles["ellipse-4"])}></div>
                <div className={cn(styles["ellipse-3"])}></div>
              </div>

              {mobile ? <MobileHeader {...{menuCallbacks}} newLogo='true'/> : <NavBar />}
            {((!isShort || (!mobile && isShort)) && <>
              {!mobile && <div className={cn(styles["img"])}>
                <picture className={cn(styles["_1-1"])}>
                  <source srcSet={imgCityWebp} type="image/webp" />
                  <img src={imgCityPng} alt='City' />
                </picture>
                <img className={cn(styles["ellipse-5"])} src={imgEllipse5} alt='' />

                <div className={cn(styles["new-world"])}>
                  <div className={cn(styles["frame-4116"])}>
                    <div className={cn(styles["we-build"])}>We build</div>
                  </div>

                  <div className={cn(styles["frame-4117"])}>
                    <div className={cn(styles["new-worlds"])}>new worlds</div>
                  </div>
                </div>
              </div>}

              <div className={cn(styles["title"])}>
                {mobile && <div className={cn(styles["new-world"])}>
                  <div className={cn(styles["frame-4116"])}>
                    <div className={cn(styles["we-build"])}>We build</div>
                  </div>

                  <div className={cn(styles["frame-4117"])}>
                    <div className={cn(styles["new-worlds"])}>new worlds</div>
                  </div>
                </div>}
                <div className={cn(styles["frame-4225"])}>
                  {mobile ? <div className={cn(styles["inter-world"])}>InterWorld</div> : <div className={cn(styles["inter-world"])}>InterWorld</div>}

                  <div
                    className={cn(styles["expmetvers"])}
                  >
                    {formatMessage({'id': 'LANDING.EXPERIENCE'})}
                  </div>
                </div>

                {!isShort && <>
                  <div className={cn(styles["button"])}>
                    {/* <Link to={`/${lang}/registration`} className={cn(styles["button2"])}>
                      <div className={cn(styles["join-to-metaverse"])}>{formatMessage({'id': 'LANDING.JOIN'})}</div>

                      <div className={cn(styles["frame-4109"])}>
                        <div className={cn(styles["ellipse-1"])}></div>

                        <img
                          className={cn(styles["icon-arrow-right-up"])}
                          width="24"
                          height="24"
                          src={imgIconArrowRightUp} alt="" />
                      </div>
                    </Link> */}

                    <div className={cn(styles["join"])}>
                      <div className={cn(styles["frame-4111"])}>
                        <div className={cn(styles["group-28"])}>
                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember1} alt='Member'
                          />

                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember2} alt='Member'
                          />

                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember3} alt='Member'
                          />

                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember4} alt='Member'
                          />
                        </div>

                        <div className={cn(styles["_200-members-joined"])}>{formatMessage({'id': 'LANDING.JOINED'})}</div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={cn(styles["top_btn__container"])}>
                    <a className={cn(styles["top_btn__download"])} href={`https://metadata.${BASE_DOMAIN}/metaverse/webdemo/adx_test/SetupFiles/InterworldSetup.zip`} download>
                      <img src={imgWindows} alt="win" />
                      <span>{formatMessage({'id': 'DOWNLOAD.FOR_WINDOWS'})}</span>
                    </a>
                  </div> */}
                </>}
              </div>
              </>)}
            </div>
            <Portal wrapperId='top-modal'>
              <MapLand {...{show, onHide:handleClose}}/>
            </Portal>
          </>);
};

export default connect(
    (state)=>({
      logged: !!state.member.id,
      mobile: state.common.mobile,
    }),
    ()=>({

    })
)(injectIntl(TopBanner));
