// import messagesCN from '../lang/cn.json';
// import messagesEN from '../lang/en.json';
// import messagesJP from '../lang/jp.json';
// import messagesKR from '../lang/kr.json';
// import messagesRU from '../lang/ru.json';
// import messagesTC from '../lang/tc.json';

export const getMessages = lang => {
    return import(`../lang/${lang}.json`).then(module => module.default);
}

export const messages = {
    cn: 'ch',
    en: 'en',
    jp: 'jp',
    kr: 'kr',
    // ru: 'ru',
    tc: 'tc',
};
export const menuLang = [
    {"href":'/en', 'label':'English'},
    {"href":'/cn', 'label':'简体中文'},
    {"href":'/tc', 'label':'繁體中文'},
    // {"href":'/ru', 'label':'Русский'},
    {"href":'/jp', 'label':'日本語'},
    {"href":'/kr', 'label':'한국어'},
];

export const checkConfirmedLang = lang => {
    return ['en', 'cn', 'tc', 'jp', 'kr'
        // , 'ru'
    ].indexOf(lang) !== -1;
};
export const langToLocale = lang => {
    switch (lang) {
        case "cn":
            return "zh-CN";
        case "tc":
            return "zh-TW";
        case "en":
            return "en-US";
        case "jp":
            return "ja-JP";
        case "kr":
            return "ko-KR";
        // case "ru":
        //     return "ru-RU";
        default:
            return "en-US";
    }
}
