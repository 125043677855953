import api from './core/api';
import qs from "qs";

class MapService {
    constructor(api) {
        this.api = api;
    }
    map() {
        let map = this.api.client.get(
            this.api.getUrl('member/map'),
        );

        return map;
    }
    districts() {
        return this.api.client.get(
            this.api.getUrl('member/map/districts'),
        );
    }
    districtSingle(x, y) {
        return this.api.client.get(
            this.api.getUrl('member/map/district'),
            { searchParams:{x,y} }
        ).json();
    }
    plotsByDistrict(x, y) {
        return this.api.client.get(
            this.api.getUrl('member/map/plots'),
            { searchParams:{x,y} }
        ).json();
    }
    plotDetails([px, py], options) {
        let searchParams = new URLSearchParams(`?x=${px}&y=${py}`);
        return this.api.client.get(
            this.api.getUrl('member/map/plot'),
            { ...options, searchParams }
        );
    }
    districtThumbnails(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/map/tiles'),
            { ...options, }
        );
    }
    myPlots(
        page = 1,
        perPage = 15,
        sort = 'id',
        direction = 'desc',
        level = null,
        dateStart = null,
        dateEnd = null,
    ) {
        const filter = new Map();
        if (level) {
            filter.set('level', level);
        }
        if (dateStart) {
            filter.set('dateStart', dateStart);
        }
        if (dateEnd) {
            filter.set('dateEnd', dateEnd);
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('member/map/plots/member'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }
    statistics() {
        return this.api.client.get(
            this.api.getUrl('member/map/statistics')
        );
    }
    reservedAreas() {
        return this.api.client.get(
            this.api.getUrl('member/map/reserved-areas')
        );
    }
}

export default (new MapService(api));
