import {injectIntl} from "react-intl";
import { connect } from "react-redux";
import { Navbar, Container, Nav  } from "react-bootstrap";
import {useEffect, useState} from "react";
import MobileMenuSection from '../main/MobileMenuSection';
import {useParams, useNavigate, useLocation} from "react-router-dom";


import { menuLang } from '../../../services/language';
import imgLogo from "../landingInterWorld/assets/old_logo_black.svg";
import { BASE_DOMAIN } from "etc/define";
import AW937HideElementsForCondition from "components/crutches/AW937HideElementsForCondition";

const Header = ({
        intl,
        menuCallbacks,
        newLogo = true,
        dark = false,
}) => {
    const {formatMessage} = intl;
    let navigate = useNavigate();
    let location = useLocation();

    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}/`, '');

    const [mobileMenuShow, setMobileMenuShow] = useState(false);

    const [v2DAccess, setV2DAccess] = useState(true);
    useEffect(() => {
        const accessString = process.env.REACT_APP_2D_ACCESS;
        setV2DAccess(accessString && accessString === 'all');
    }, []);

    useEffect(() => {
        setMobileMenuShow(false);
    }, [location]);

    const Mobile = <div>
    <style>
        {`
            .mobile-menu, .mobile-menu * {
                font-family: 'Rubik', sans-serif;
            }
        `}
    </style>
    <Navbar className="main-header" variant="light" bg={dark ? 'white' : undefined}>
            <Container fluid={true} className="px-3">
                <Navbar.Brand href={[`/${lang}`, `/${lang}/`, ''].indexOf(postfix) !== -1 ? null: `/${lang}`} className={"py-0"}>
                    {newLogo ? <img
                        width="160"
                        src={imgLogo} alt="InterWorld"/> : <i className={"faw-logo d-inline-block align-top"} style={{'color':'#f0a920','fontSize':'2.5rem'}} >
                            <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span><span className="path12"></span>
                            </i>}
                </Navbar.Brand>
                <Navbar.Collapse>
                    <Nav className={"w-100 align-items-center justify-content-end header--right-nav"}>
                        <button className={"mobile-header-menu" + (mobileMenuShow ? ' active' : '')} onClick={() => {

                            setMobileMenuShow(!mobileMenuShow);
                        }} >
                            <i className={"faw-burger"} style={{'fontSize':'0.938rem', color: '#9546D3'}} />
                        </button>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        {mobileMenuShow && <div className="mobile-menu">
            <AW937HideElementsForCondition>
                {v2DAccess && <MobileMenuSection
                    {...{
                        label: formatMessage({'id': 'HEADER.2D'}),
                        onLabelClick: () => {window.open(`https://2d.${BASE_DOMAIN}/`, "_blank");  setMobileMenuShow(false);},
                        activeRoutes: ['2daw']
                    }}
                />}
            </AW937HideElementsForCondition>
            <MobileMenuSection
                {...{
                    // sectionClass: 'with-border',
                    label: formatMessage({'id': 'LINK.HOME'}),
                    onLabelClick: () => { navigate(`/${lang}`) },
                    activeRoutes: ['']
                }}
            />
            {/* <MobileMenuSection
                {...{
                    label: formatMessage({'id': 'LINK.DOWNLOADS'}),
                    onLabelClick: () => { navigate(`/${lang}/downloads`) },
                    activeRoutes: ['downloads']
                }}
            /> */}
            <MobileMenuSection
                {...{
                    label: formatMessage({'id': 'LINK.ABOUT'}),
                    onLabelClick: () => { navigate(`/${lang}/about`) },
                    activeRoutes: ['about']
                }}
            />
            <MobileMenuSection
                {...{
                    label: formatMessage({'id': 'LINK.MAP'}),
                    onLabelClick: () => { menuCallbacks['map']();  setMobileMenuShow(false);},
                    activeRoutes: ['#']
                }}
            />
            {/*<MobileMenuSection*/}
            {/*    {...{*/}
            {/*        label: formatMessage({'id': 'LINK.PARTNERS'}),*/}
            {/*        onLabelClick: () => { navigate(`/${lang}/partners`) },*/}
            {/*        activeRoutes: ['ads-info']*/}
            {/*    }}*/}
            {/*/>*/}
            {/* {process.env.REACT_APP_DAO_LINK === 'enabled' && <MobileMenuSection
                {...{
                    label: 'DAO',
                    onLabelClick: () => { navigate(`/${lang}/dao`) },
                    activeRoutes: ['dao']
                }}
            />} */}
            {/*<MobileMenuSection*/}
            {/*    {...{*/}
            {/*        label: formatMessage({'id': 'LINK.MARKETPLACE'}),*/}
            {/*        onLabelClick: () => { navigate(`/${lang}/marketplace-info`) },*/}
            {/*        activeRoutes: ['marketplace-info']*/}
            {/*    }}*/}
            {/*/>*/}
            {/* <MobileMenuSection
                {...{
                    label: formatMessage({'id': 'HEADER.ANNOUNCEMENTS'}),
                    onLabelClick: () => { navigate(`/${lang}/public-announcements`) },
                    activeRoutes: ['public-announcements']
                }}
            /> */}

            {/* <MobileMenuSection
                {...{
                    sectionClass: 'with-border',
                    label: formatMessage({'id': 'LINK.LOGIN'}),
                    onLabelClick: () => { navigate(`/${lang}/login`) },
                    activeRoutes: ['login']
                }}
            />
            <MobileMenuSection
                {...{
                    label: formatMessage({'id': 'LOGIN.SIGN_UP'}),
                    onLabelClick: () => { navigate(`/${lang}/registration`) },
                    activeRoutes: ['registration']
                }}
            /> */}

            <MobileMenuSection
                {...{
                    sectionClass: 'with-border',
                    label: menuLang.find(({href}) => href === `/${lang}`).label,
                }}
            >
                {
                    menuLang.filter(({href}) => href !== `/${lang}`)
                        .map(({href, label}, indx) => {
                            const url = postfix.match(/^\//) ? href : `${href}/${postfix}`;
                            return <a key={indx} onClick={() => window.location = url} className={'mobile-menu--item' + ([`announcements`].indexOf(postfix) !== -1 ? " active":'')} href={`#`}>{label}</a>
                        })
                }
            </MobileMenuSection>

        </div>}
    </div>


    return (Mobile);
}

export default connect(
    (state) => ({
    }),
    (dispatch) => ({

    }),
)(injectIntl(Header));
