import api from './core/api';
import qs from "qs";
import { DateTime } from "luxon";

class Ads {
    constructor(api) {
        this.api = api;
    }
    list({
        page = 1,
        perPage = 10,
        sort = 'createdAt',
        direction = 'desc',
        archive,
        filter = {
          id: undefined,
          search: ''
        },
    }, signal) {
        const searchParams = {page, perPage, sort, direction, filter, archive};
        return this.api.client.get(
            this.api.getUrl('member/ad/list'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }

    create(params, signal) {
        const searchParams = new URLSearchParams();
        if (params.title)
            searchParams.set('title', params.title);
        if (params.imageId)
            searchParams.set('imageId', params.imageId);
        if (params.categoryId)
            searchParams.set('categoryId', params.categoryId);
        if (params.description)
            searchParams.set('description', params.description);

        if (params.siteUrl)
            searchParams.set('siteUrl', params.siteUrl);
        if (params.mediaUrl) {
            searchParams.set('mediaUrl', params.mediaUrl);
        } else if (params.mediaId !== undefined) {
            searchParams.set('mediaId', params.mediaId);
        }

        return this.api.client.post(
            this.api.getUrl('member/ad'),
            {body: searchParams, signal}
        );
    }

    update(id, params, signal) {
        const searchParams = new URLSearchParams();
        searchParams.set('adId', id);
        searchParams.set('categoryId', params.categoryId);
        searchParams.set('description', params.description);

        searchParams.set('title', params.title);
        if (params.imageId !== undefined)
            searchParams.set('imageId', params.imageId);

        searchParams.set('siteUrl', params.siteUrl ?? "");
        if (params.mediaUrl) {
            searchParams.set('mediaUrl', params.mediaUrl);
        } else if (params.mediaId !== undefined) {
            searchParams.set('mediaId', params.mediaId);
        }

        return this.api.client.put(
            this.api.getUrl('member/ad'),
            {body: searchParams, signal}
        );
    }

    single(id, signal) {
        return this.api.client.get(
          this.api.getUrl('member/ad'),
          {searchParams: qs.stringify({adId: id}), signal}
        );
    }

    catalogDetails(id, signal) {
        return this.api.client.get(
          this.api.getUrl('public/ad'),
          {searchParams: qs.stringify({adId: id}), signal}
        );
    }

    delete(id, signal) {
        return this.api.client.delete(
            this.api.getUrl('member/ad'),
            {searchParams: qs.stringify({adId: id}), signal}
        );
    }

    uploadFile(file, original = null, options = {}) {
        const formData = new FormData();

        formData.append('file', file);
        if (original) {
            formData.append('original', original);
        }

        return this.api.client.post(
            this.api.getUrl('member/ad/upload'),
            {...options,
                timeout: 10*60*1000,
                body: formData,
            }
        );
    }

    listBuilds(adId, signal) {
        const searchParams = { adId };

        return this.api.client.get(
            this.api.getUrl('member/ad/placement/buildings'),
            { searchParams: qs.stringify( searchParams ) , signal, timeout: 10*60*1000}
        );
    }
    placeBuilds(json = {}, signal) {
        return this.api.client.post(
            this.api.getUrl('member/ad/placement'),
            { json, signal}
        );
    }

    credits(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/credit/balance'),
            {signal}
        );
    }

    versionList({
        page = 1,
        perPage = 10,
        sort = 'createdAt',
        direction = 'desc',
        filter = {
          id: undefined,
        },
        adId
    }, signal) {
        const searchParams = {page,  sort, direction, filter, adId};
        return this.api.client.get(
            this.api.getUrl('member/ad/versions'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }

    creditsHistory(
        page = 1,
        perPage = 10,
        sort = 'id',
        direction = 'desc',
        id = null,
        type = null,
        adxPurchaseId = null,
        adId = null,
        dateFrom = null,
        dateTo = null
    ) {
        const filter = new Map();
        if(id) {
            filter.set('id', id);
        }
        if(type) {
            filter.set('type', type);
        }
        if(adxPurchaseId) {
            filter.set('adxPurchaseId', adxPurchaseId);
        }
        if(adId) {
            filter.set('adId', adId);
        }

        if(dateFrom) {
            filter.set('dateStart', DateTime.fromSQL(dateFrom, {zone: 'UTC-6', locale: 'en'}).toISO());
        }
        if(dateTo) {
            filter.set('dateEnd', DateTime.fromSQL(dateTo, {zone: 'UTC-6', locale: 'en'}).set({ hour: 23, minute: 59, second: 59 }).toISO());
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('member/ad/credit/transactions'),
            { searchParams: qs.stringify( searchParams ), timeout: 10*60*1000 }
        );
    }

    getAdNetVisibility() {
        return this.api.client.get(
            this.api.getUrl('member/ad/credit/packages?page=1&perPage=1')
        ).then(response => response.json()).then(({data}) => {
            return data.length > 0;
        }).catch(() => {
            return false;
        });
    }

    schedule(
        page = 1,
        perPage = 10,
        sort = 'id',
        direction = 'desc',
        status = null,
        dateFrom = null,
        dateTo = null
    ) {
        const filter = new Map();
        if(status) {
            filter.set('status', status);
        }

        if(dateFrom) {
            filter.set('afterDate', DateTime.fromSQL(dateFrom, {zone: 'UTC-6', locale: 'en'}).toISO());
        }
        if(dateTo) {
            filter.set('beforeDate', DateTime.fromSQL(dateTo, {zone: 'UTC-6', locale: 'en'}).set({ hour: 23, minute: 59, second: 59 }).toISO());
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('member/ad/network/schedule'),
            { searchParams: qs.stringify( searchParams ), timeout: 10*60*1000 }
        );
    }

    pricerPerDay(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/network/daily-price'),
            {signal}
        );
    }

    lastUpdate(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/statistics/last-update'),
            {signal}
        );
    }

    memberStatistics(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/statistics/total'),
            {signal}
        );
    }

    memberStatisticsActivity(
        page = 1,
        perPage = 10,
        sort = 'id',
        direction = 'desc',
        dateFrom = null,
        dateTo = null
    ) {
        const filter = new Map();

        if(dateFrom) {
            filter.set('dateStart', dateFrom);
        }
        if(dateTo) {
            filter.set('dateEnd', dateTo);
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('member/ad/statistics/member/daily'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }

    pupblickAdsList(
        page = 1,
        perPage = 10,
        sort = 'id',
        direction = 'desc',
        categoryId = 0,
        search = ""
    ) {
        const filter = new Map();

        if(categoryId) {
            filter.set('categoryId', categoryId);
        }
        if(search) {
            filter.set('search', search);
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('public/ad/list'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }

    networkStatus(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/network/buildings/status'),
            {signal}
        );
    }

    loadTemplates (
        page = 1,
        perPage = 10
    ) {
        const searchParams = { page, perPage};
        return this.api.client.get(
            this.api.getUrl('member/ad/template/list'),
            { searchParams: qs.stringify( searchParams )}
        );
    }

    applyTemplate(adId) {

        const searchParams = new URLSearchParams();

        searchParams.set('adId', adId);

        return this.api.client.post(
            this.api.getUrl('member/ad/template/copy'),
            {body: searchParams}
        );
    }

    memberStatisticsToday(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/statistics/member/today'),
            {signal}
        );
    }

    catalogTotals(signal) {
        return this.api.client.get(
            this.api.getUrl('public/ad/totals'),
            {signal}
        );
    }

    catalogCategories(signal) {
        return this.api.client.get(
            this.api.getUrl('public/ad/categories'),
            {signal}
        );
    }

    networkAdd(id, signal) {
        return this.api.client.post(
            this.api.getUrl(`member/ad/network/add?adId=${id}`),
            {signal}
        );
    }

    updateNetworkState(state, signal) {
        return this.api.client.post(
            this.api.getUrl(state ? `member/ad/network/buildings/enable` : `member/ad/network/buildings/disable`),
            {signal}
        );
    }

    networkRemove(id, signal) {
        return this.api.client.post(
            this.api.getUrl(`member/ad/network/remove?adId=${id}`),
            {signal}
        );
    }

    networkAddAll(signal) {
        return this.api.client.post(
            this.api.getUrl(`member/ad/network/add-all`),
            {signal}
        );
    }

    networkRemoveAll(signal) {
        return this.api.client.post(
            this.api.getUrl(`member/ad/network/remove-all`),
            {signal}
        );
    }

    getCategory(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/categories'),
            {signal}
        );
    }

    memberCanCreate(signal) {
        return this.api.client.get(
            this.api.getUrl('member/ad/can-create'),
            {signal}
        );
    }
    scrape (url = '') {
        const searchParams = { url };
        return this.api.client.get(
            this.api.getUrl('member/ad/scrape'),
            { searchParams: qs.stringify( searchParams )}
        );
    }
}

export default (new Ads(api));
