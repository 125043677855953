import api from "./core/api";
import qs from "qs";

class GoldRush {
    constructor(api) {
        this.api = api;
    }
    balances(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/gold-rush/balances'), options
        );
    }
    historyMining(
        page = 1,
        perPage = 15,
        sort = 'id',
        direction = 'desc',
        dateStart = null,
        dateEnd = null,
        options = {},
    ) {
        const filter = new Map();
        if (dateStart) {
            filter.set('dateStart', dateStart);
        }
        if (dateEnd) {
            filter.set('dateEnd', dateEnd);
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};

        return this.api.client.get(
            this.api.getUrl('member/gold-rush/history/mining'), {...options, searchParams: qs.stringify( searchParams ) }
        );
    }
    historyRedeem(
        page = 1,
        perPage = 15,
        sort = 'id',
        direction = 'desc',
        dateStart = null,
        dateEnd = null,
        options = {},
    ) {
        const filter = new Map();
        if (dateStart) {
            filter.set('dateStart', dateStart);
        }
        if (dateEnd) {
            filter.set('dateEnd', dateEnd);
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};

        return this.api.client.get(
            this.api.getUrl('member/gold-rush/history/redeem'), {...options, searchParams: qs.stringify( searchParams ) }
        );
    }
    lands(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/gold-rush/lands'), options
        );
    }
    miningStart(plotId, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/gold-rush/mining/start'),
            {...options, json:{plotId}}
        );
    }
    miningFinish(plotId, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/gold-rush/mining/finish'),
            {...options, json:{plotId}}
        );
    }
    miningStatus(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/gold-rush/mining/status'), options
        );
    }
    redeem(minerals, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/gold-rush/redeem'),
            {...options, json:{minerals}}
        );
    }
    redeemCalculate(minerals, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/gold-rush/redeem/calculate'),
            {...options, json:{minerals}}
        );
    }
    shovelPurchase(options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/gold-rush/shovel/purchase'), options
        );
    }
    shovelStatus(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/gold-rush/shovel/status'), options
        );
    }
}

export default (new GoldRush(api));
