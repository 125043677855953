import {
    INIT_MAP,
    SET_DISTRICTS,
    SET_MAP_MQTT_PLOT_UPDATE_PAYLOAD,
    SET_MAP_REQUEST_ALREADY_SENT,
    SET_MAP_DISTRICT_THUMBNAILS,
    SET_MAP_SHOW_IMAGES,
    CHANGE_PLOT, SET_MAP_IS_RENDER_THUMBNAILS,
    SET_MAP_BUILTBUILDINGS,
    ADD_MAP_BUILTBUILDINGS,
    DELETE_MAP_BUILTBUILDINGS,
} from '../actions';
import { produce } from 'immer';

const initialState = {
    data: new Map(),
    districts: null,
    mqttPlotUpdatePayload: null,
    mapRequestAlreadySent: false,
    districtThumbnails: [],
    showImages: false,
    isRenderThumbnails: false,
    builtBuildings: new Map(),
};
if (localStorage.getItem('mapShowImages')) {
    initialState.showImages = !!parseInt(localStorage.getItem('mapShowImages'));
}

export default (state = initialState, action) => {
    switch (action.type) {
        case INIT_MAP:
            return {...state, data:action.payload};
        case SET_DISTRICTS:
            return {...state, districts:action.payload};
        case SET_MAP_MQTT_PLOT_UPDATE_PAYLOAD:
            return {...state, mqttPlotUpdatePayload:action.payload};
        case SET_MAP_REQUEST_ALREADY_SENT:
            return {...state, mapRequestAlreadySent:action.payload};
        case SET_MAP_SHOW_IMAGES:
            return {...state, showImages:action.payload};
        case CHANGE_PLOT:
            return produce(state, (draftState) => {
                let el = action.payload,
                    key = `${el[0]}:${el[1]}`;
                if (draftState.data.has(key)) {
                    draftState.data.set(key, el);
                }
            })
        case SET_MAP_DISTRICT_THUMBNAILS:
            return {...state, districtThumbnails:action.payload};
        case SET_MAP_IS_RENDER_THUMBNAILS:
            return {...state, isRenderThumbnails:action.payload};
        case SET_MAP_BUILTBUILDINGS:
            return {...state, builtBuildings: new Map(action.payload.map(item => {
                const [sx, sy, ex, ey] = item;
                return [`${sx}:${sy}:${ex}:${ey}`, item];
            }))};
        case ADD_MAP_BUILTBUILDINGS:
            return produce(state, (draftState) => {
                const [sx, sy, ex, ey] = action.payload;
                const key = `${sx}:${sy}:${ex}:${ey}`;
                draftState.builtBuildings.set(key, action.payload);
            })
        case DELETE_MAP_BUILTBUILDINGS:
            return produce(state, (draftState) => {
                const [sx, sy, ex, ey] = action.payload;
                const key = `${sx}:${sy}:${ex}:${ey}`;
                draftState.builtBuildings.delete(key);
            })
        default:
            return state;
    }
};
