export const humanizeSeconds = (time) => {
    const hours = Math.floor(time / (60 * 60));
    const minutes = Math.floor((time - (hours * 60 * 60)) / 60);
    const seconds = time % 60;

    let parts = [
        minutes,
        seconds,
    ];
    if (hours > 0) {
        parts.unshift(hours);
    }
    return parts.map((x) => {
        if (x < 10) {
            return `0${x}`;
        }
        return x;
    }).join(':');
};

export const formatDate = (nd, withTime = true) => {
    const [
        year,
        month,
        day,
        hours,
        minutes,
        seconds
    ] = [
        nd.getUTCFullYear(),
        nd.getUTCMonth()+1,
        nd.getUTCDate(),
        nd.getUTCHours(),
        nd.getUTCMinutes(),
        nd.getUTCSeconds()
    ].map((number => (number > 9 ? number : `0${number}`)));

    if (withTime) {
        return `${[year,month,day].join('-')} ${[hours,minutes,seconds].join(':')}`;
    }
    return `${[year,month,day].join('-')}`;
};

export const toCSTTime = (d) => {
    d = new Date(d);
    const utc = d.getTime();
    const offset = -6;
    const cst = utc + (3600000 * offset);
    return new Date(cst);
};

export const getExpiredAt = (expiredIn) => {
    return new Date((+new Date()) + (expiredIn*1000));
};

export const getMoneyValue = (moneyObject) => {
    if (moneyObject.fractionalPart === '') {
        return moneyObject.integerPart;
    }
    return moneyObject.decimal;
};
export const hidePhoneNumber = (phone) => {
    const hidden = [],
        splitted = phone.split(' ');
    for (let i = 0; i < splitted.length; i++) {
        if (i === 0 || i === splitted.length - 1) {
            hidden.push(splitted[i]);
            continue;
        }
        let block = [];
        for (let j = 0; j < splitted[i].length; j++) {
            block.push('*');
        }
        hidden.push(block.join(''));
    }
    return hidden.join(' ');
};
export const setCaretPosition = (ctrl, pos) => {
    if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);
    } else if (ctrl.createTextRange) {
        let range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
    }
};
export const localStorageClearNot = (props) => {
    if (props) {
        let saved = props.map(el => {
            return localStorage.getItem(el);
        });
        localStorage.clear();
        saved.forEach((val, i) => {
            if (val !== null)
                localStorage.setItem(props[i], val);
        })
    } else {
        localStorage.clear();
    }
}

export const romanize = (num) => {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
            "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
            "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export const spliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    while (arr.length > 0) {
        const chunk = arr.splice(0, chunkSize);
        res.push(chunk);
    }
    return res;
}

export const capitalizeFirstLetter = (string) => {
    return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}
export const isEmpty = (value) => {
    return value === undefined || value === null;
}

export default {
    formatDate,
    toCSTTime,
    getExpiredAt,
    getMoneyValue,
    humanizeSeconds,
    hidePhoneNumber,
    setCaretPosition,
    localStorageClearNot,
    romanize,
    spliceIntoChunks,
    capitalizeFirstLetter,
    isEmpty
};
