import api from './core/api';
import qs from "qs";

class Announcements {
    constructor(api) {
        this.api = api;
    }
    list({
        page = 1,
        perPage = 3,
        sort = 'pinned',
        direction = 'desc',
        lang = 'en'
    }) {
        const searchParams = { page, perPage, sort, direction, lang };
        return this.api.client.get(
            this.api.getUrl('member/announcements'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }
    unread(lang) {
        const searchParams = { lang };
        return this.api.client.get(
            this.api.getUrl('member/announcements/unread'),
            { searchParams: qs.stringify( searchParams ) }
        );
    }
}

export default (new Announcements(api));
