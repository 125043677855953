import {
    SET_LY_STATE,
    SET_LY_PLOTS,
    SET_LY_PLOT,
    SET_LY_INVENTORY,
    SET_LY_MATERIALS,
    SET_PRODUCTS, SET_LY_TO_BUY,
    SET_LY_BUY_PRODUCTS_LOADING,
    SET_LY_FORBIDDEN_ACTIONS, SET_LY_AWARD,
    SET_LY_SHOP_OPEN, SET_LY_PURCHASED,
    SET_LY_PLOT_LIST_OPEN,
    SET_LY_SHOP_DATA, SET_LY_PLOTS_LOAD,
    SET_SHOW_PLOTS_MODAL,
    SET_SHOW_WAREHOUSE_MODAL,
} from '../actions';

const initialState = {
    state: 'default',
    plots: [],
    plotsLoad: true,
    showPlotsModal: false,
    showWarehouseModal: false,
    inventory: {},
    products: [],
    materials: [],
    buyProductsLoading: false,
    plot: {coordinates:false},
    toBuy: {},
    forbiddenActions:false,
    award: {},
    shopOpen: '',
    shopData: {},
    purchase:{},
    plotListOpen: false,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_LY_STATE:
            return {...state, state:action.payload}
        case SET_LY_TO_BUY:
            return {...state, toBuy:action.payload}
        case SET_LY_PLOTS:
            return {...state, plots:action.payload}
        case SET_LY_PLOT:
            return {...state, plot:action.payload}
        case SET_LY_INVENTORY:
            return {...state, inventory:action.payload}
        case SET_LY_MATERIALS:
            return {...state, materials:action.payload}
        case SET_PRODUCTS:
            return {...state, products:action.payload}
        case SET_LY_AWARD:
            return {...state, award:action.payload}
        case SET_LY_BUY_PRODUCTS_LOADING:
            return {...state, buyProductsLoading:action.payload}
        case SET_LY_FORBIDDEN_ACTIONS:
            return {...state, forbiddenActions:action.payload}
        case SET_LY_SHOP_OPEN:
            return {...state, shopOpen:action.payload}
        case SET_LY_SHOP_DATA:
            return {...state, shopData:action.payload}
        case SET_LY_PURCHASED:
            return {...state, purchase:action.payload}
        case SET_LY_PLOT_LIST_OPEN:
            return {...state, plotListOpen:action.payload}
        case SET_LY_PLOTS_LOAD:
            return {...state, plotsLoad:action.payload}
        case SET_SHOW_PLOTS_MODAL:
            return {...state, showPlotsModal:action.payload}
        case SET_SHOW_WAREHOUSE_MODAL:
            return {...state, showWarehouseModal:action.payload}
        default:
            return state;
    }
};
