import api from './core/api';

class Login {
    constructor(api) {
        this.api = api;
        this.once = false;
    }
    login(username, password) {
        return this.api.client.post(
            this.api.getUrl('member/login'),
            { json: {username, password} }
        );
    }
    logout() {
        return this.api.client.post(
            this.api.getUrl('member/logout'),
        );
    }
    loginByOtp(otp, member_id) {
        return this.api.client.get(
            this.api.getUrl('member/login-by-otp'),
            { searchParams:{otp, member_id} }
        );
    }
    member() {
        return this.api.client.get(
            this.api.getUrl('member'),
        );
    }

    yesnoLink() {
        return this.api.client.get(
            this.api.getUrl('member/live2earn/relocation'),
        );
    }

    refresh() {
        this.api.reinit();
        return this.api.client.post(
            this.api.getUrl('member/refresh'),
        );
    }
}

export default (new Login(api));
