import api from "./core/api";
import qs from "qs";

class LumberYard {
    constructor(api) {
        this.api = api;
    }
    harvesting(treeId, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/lumber-yard/harvesting'),
            {...options, json:{treeId}}
        );
    }
    history(
        page = 1,
        perPage = 15,
        sort = 'id',
        direction = 'desc',
        action = null,
        dateStart = null,
        dateEnd = null,
        options = {},
    ) {
        const filter = new Map();
        if (action) {
            filter.set('action', action);
        }
        if (dateStart) {
            filter.set('beforeDate', dateStart);
        }
        if (dateEnd) {
            filter.set('afterDate', dateEnd);
        }
        const searchParams = {page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};

        return this.api.client.get(
            this.api.getUrl('member/lumber-yard/history'),
            {...options, searchParams: qs.stringify( searchParams ) }
        );
    }
    inventory(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/lumber-yard/inventory'), options
        );
    }
    materials(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/lumber-yard/materials'), options
        );
    }
    planting(plotId, position, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/lumber-yard/planting'),
            {...options, json:{plotId, position}}
        );
    }
    plot(plotId, options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/lumber-yard/plot'), {...options, searchParams: qs.stringify({plotId})}
        );
    }
    plots(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/lumber-yard/plots'), options
        );
    }
    watering(treeId, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/lumber-yard/watering'),
            {...options, json:{treeId}}
        );
    }
    products(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/purchase/products'), options
        );
    }
    purchase(productId, quantity, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/purchase'), {...options, json:{productId, quantity}}
        );
    }
}

export default (new LumberYard(api));
