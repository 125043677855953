import { Level } from "../models/map.interfaces";

// export const LAND_FILE = '/abey_world_map.png';

export const LAND_FILE = '/abey_world.svg';
export const LAND_FILE_SIZE = Object.freeze({
  width: 1524,
  height: 1080,
});

export const scale = 10;

export const LAND = Object.freeze({
  width: LAND_FILE_SIZE.width/scale,
  height: LAND_FILE_SIZE.height/scale,
});

export const levels: Level[] = [
  {
    style: {
      color: '#fff',
      fillColor: '#fff',
      fillOpacity: 1,
      weight: .1,
    },
    props: {

    }
  },
  {
    style: {
      color: '#000',
      fillOpacity: 1,
      fillColor: '#C1F4FFff',
      weight: .1,
    },
    props: {

    }
  },
  {
    style: {
      color: '#000',
      fillOpacity: 1,
      fillColor: '#88d3e5ff',
      weight: .1,
    },
    props: {

    }
  },
  {
    style: {
      color: '#000',
      fillOpacity: 1,
      fillColor: '#56c4ddff',
      weight: .1,
    },
    props: {

    }
  },
  {
    style: {
      color: '#000',
      fillOpacity: 1,
      fillColor: '#35a8e8ff',
      weight: .1,
    },
    props: {

    }
  },
];

export const abeyLogos: [[number , number],[number , number]][] = [
    [
        [-35, -70],
        [10,10]
    ],
    [
        [-35, -81],
        [10,10]
    ],
    [
        [-35, -105],
        [10,10]
    ],
    [
        [-70, -105],
        [10,10]
    ],
    [
        [-105, -105],
        [10,10]
    ],
    [
        [-105, -70],
        [10,10]
    ],
    [
        [-105, -35],
        [10,10]
    ],
    [
        [-81, -35],
        [10,10]
    ],
    [
        [-70, -35],
        [10,10]
    ]
];

export const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN || 'interworld.io';
