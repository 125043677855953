import { combineReducers} from "redux";
import common from "./common";
import mqtt from "./mqtt";
import login from "./login";
import member from "./member";
import mapfilter from "./mapfilter";
import map from "./map";
import goldrush from "./goldrush";
import marketplace from "./marketplace";
import registration from "./registration";
import lumberyard from "./lumberyard";
import promt from "./promt";
import error from "./error";
import crossSuccess from "./crossSuccess";
import lastTablePage from "./lastTablePage";

export default combineReducers({
    common,
    mqtt,
    login,
    member,
    mapfilter,
    map,
    goldrush,
    registration,
    lumberyard,
    marketplace,
    promt,
    error,
    crossSuccess,
    lastTablePage
});
