import {
    LOGIN_PROCESS, SET_ACCESS_TOKEN, SET_MEMBER_ID,
    SET_OTP, SET_EXPIRED_AT
} from '../actions';

const initialState = {
    loginProcess: false,
    accessToken: '',
    expiredAt: new Date(),
    otp:'',
    memberId:'',
};

if (localStorage.getItem('accessToken')) {
    initialState.accessToken = localStorage.getItem('accessToken');
}

if (localStorage.getItem('expiredAt')) {
    initialState.expiredAt = new Date(
        parseInt(
            localStorage.getItem('expiredAt')
        )
    );
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_PROCESS:
            return {...state, loginProcess:action.payload};
        case SET_ACCESS_TOKEN:
            return {...state, accessToken:action.payload};
        case  SET_OTP:
            return {...state, otp:action.payload};
        case SET_MEMBER_ID:
            return {...state, memberId: action.payload};
        case SET_EXPIRED_AT:
            return {...state, expiredAt: action.payload};
        default:
            return state;
    }
};
