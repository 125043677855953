import {
    SET_MQTT_CLIENT,
    SET_MQTT_CONNECT_STATUS,
} from '../actions';

const initialState = {
    client:null,
    connectStatus:'Connect',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MQTT_CLIENT:
            return {...state, client: action.payload};
        case SET_MQTT_CONNECT_STATUS:
            return {...state, connectStatus: action.payload};
        default:
            return state;
    }
};
