import { connect } from 'react-redux';
import { useEffect } from 'react';

import cn from "classnames";
import styles from "../assets/styles/styles.module.scss";

import TopBanner from './TopBanner';
import Second from './Second';
import Three from './Three';
import Footer from './Footer';

const Landing = ({intl, mobile}) => {
    useEffect(() => {
        if (mobile) {
            document.querySelector("body")
                .setAttribute('class', 'mobile overflow-auto');
        }
    }, [mobile]);
    return (
        <div className={cn(styles.wrapper)}>
            <TopBanner/>
            <Second/>
            <Three/>
            <Footer/>
        </div>
    );
};

export default connect(
    (state)=>({
        mobile: state.common.mobile,
    }),
    ()=>({

    })
)(Landing);
