import ky from 'ky';
import makeCatchHandler from './makeCatchHandler';

class KyWrapper {
    constructor(client) {
        this.client = client;
        if (!KyWrapper._instance) {
            KyWrapper._instance = this;
        }
        return KyWrapper._instance;
    }
    create() {
        const args = Array.prototype.slice.call(arguments);
        this.client = this.client.create.apply(this.client, args);
        return this;
    }
    get() {
        const args = Array.prototype.slice.call(arguments);
        return this.commonCatchHandler(this.client.get.apply(this.client, args));
    }
    delete() {
        const args = Array.prototype.slice.call(arguments);
        return this.commonCatchHandler(this.client.delete.apply(this.client, args));
    }
    post() {
        const args = Array.prototype.slice.call(arguments);
        return this.commonCatchHandler(this.client.post.apply(this.client, args));
    }
    put() {
        const args = Array.prototype.slice.call(arguments);
        return this.commonCatchHandler(this.client.put.apply(this.client, args));
    }
    commonCatchHandler(action) {
        if (this.catchCallback) {
            return action
                .catch(this.catch)
                .catch(this.catchCallback);
        }
        return action
            .catch(this.catch);
    }
    catch(err) {
        return makeCatchHandler()(err);
    }
}

export default (new KyWrapper(ky));
