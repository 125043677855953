import client from './kywrapper';
import makeTokenName from "./makeTokenName";

class ApiService {
    constructor(client) {
        this.client = client;
        this.reinit();
    }
    reinit()
    {
        let accessToken = localStorage.getItem(makeTokenName());
        this.setAccessToken(accessToken
            ? accessToken
            : '');
    }
    getUrl(endpoint)
    {
        return `${process.env.REACT_APP_API_URL}api/${endpoint}`;
    }

    setLang(lang) {
        this.client = this.client.create({
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
                'Accept': 'application/json',
                'Accept-Language': lang ?? 'en'
            }
        });
        return this;
    }

    setAccessToken(token) {
        this.accessToken = token;
        this.client = this.client.create({
            headers: {
                'Authorization': `Bearer ${this.accessToken}`,
                'Accept': 'application/json',
                'Accept-Language': localStorage.getItem('Accept-Language') ?? 'en'
            }
        });
        return this;
    }
    getAccessToken() {
        return this.accessToken;
    }
    isHasAccessToken() {
        return this.accessToken.length;
    }
}

export default (new ApiService(client));
