import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

import {
    setOpenSubmenu,
    setLyShopOpen,
    setLyPlotListOpen,
    setIsShortMenu
} from "../actions";
import {useEffect, useCallback} from "react";

const CommonHandler = ({children, mobile, setOpenSubmenu, setShopOpen, setPlotListOpen, setIsShortMenu}) => {
    const location = useLocation();

    const forLumberYard = (event) => {
        if (mobile) {
            return false;
        }
        let element = event.target,
            isShop = Array.from(element.classList).indexOf('icon') !== -1
                || Array.from(element.parentNode.classList).indexOf('icon') !== -1
                || Array.from(element.parentNode.classList).indexOf('tree_empty') !== -1,
            isPlotList = false;

        while(element.parentNode.id !== 'root'
            && element.parentNode !== null
            && !isShop
            ) {
            isShop = Array.from(element.parentNode.classList).indexOf('shop__wrap') !== -1;
            element = element.parentNode;
        }
        if (!isShop) {
            setShopOpen('');
        }

        element = event.target;
        while(element.parentNode.id !== 'root'
            && element.parentNode !== null
            && !isPlotList
            ) {
            isPlotList = Array.from(element.parentNode.classList).indexOf('plots') !== -1;
            element = element.parentNode;
        }
        if (!isPlotList) {
            setPlotListOpen(false);
        }
    };

    const handleOnClick = (event) => {

        let element = event.target,
            isMenu = false;
        if(element.parentNode.classList !== undefined && Array.from(element.parentNode.classList).indexOf('modal') !== -1)
            return event;
        while(element.parentNode.id && element.parentNode.id !== 'root' && element.parentNode !== null && element.parentNode !== undefined && !isMenu) {
            isMenu = element.parentNode.classList !==undefined && Array.from(element.parentNode.classList).indexOf('dropdown-menu') !== -1;
            element = element.parentNode;
        }
        if (!isMenu) {
            setOpenSubmenu('');
        }

        if (location.pathname.includes('lumber-yard')) {
            forLumberYard(event);
        }
    };

    const checkAndSetShortMenu = useCallback(() => {
        setIsShortMenu(window.innerWidth <= 1364);
    }, [setIsShortMenu]);

    useEffect(() => {
        checkAndSetShortMenu();
        window.addEventListener('resize', event => {
            checkAndSetShortMenu();
        });
    }, [checkAndSetShortMenu])

    return (<div className="h-100" onClick={handleOnClick}>
        {children}
    </div>);
};

export default connect(
    (state) => ({
        mobile:state.common.mobile,
        openSubmenu:state.common.openSubmenu,
    }),
    (dispatch) => ({
        setOpenSubmenu: (openSubmenu) => {
            dispatch(setOpenSubmenu(openSubmenu));
        },
        setShopOpen: (shopOpen) => {
            dispatch(setLyShopOpen(shopOpen));
        },
        setPlotListOpen: (shopOpen) => {
            dispatch(setLyPlotListOpen(shopOpen));
        },
        setIsShortMenu: (isShortMenu) => {
            dispatch(setIsShortMenu(isShortMenu));
        },
    }),
)(CommonHandler);
