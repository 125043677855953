import {
    MODE_SELECTOR_EXPAND,
    SELECT_ACTIVE_MODE,
} from '../actions';

const initialState = {
    'modeSelectorExpanded':false,
    'modeSelectorActive':'region',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MODE_SELECTOR_EXPAND:
            return {...state, modeSelectorExpanded: action.payload};
        case SELECT_ACTIVE_MODE:
            return {...state, modeSelectorActive: action.payload};
        default:
            return state;
    }
}
