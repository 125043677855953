import {
    SET_REGISTRATION_PHONE_TO_SEND,
    SET_REGISTRATION_EMAIL_TO_SEND,
    SET_REGISTRATION_PHONE_TO_RENDER,
    SET_REGISTRATION_DECAY_SECONDS,
    SET_REGISTRATION_AVAILABLE_IN,
    SET_REGISTRATION_PHONE_COUNTRY,
    SET_REGISTRATION_TOKEN,
    SET_REGISTRATION_MEMBER_ID,
} from '../actions';

const initialState = {
    emailToSend:'',
    phoneToSend:'',
    phoneCountry:'',
    phoneToRender:'',
    token:'',
    decaySeconds:0,
    availableIn:0,
    memberId:'',
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_REGISTRATION_PHONE_COUNTRY:
            return {...state, phoneCountry:action.payload}
        case SET_REGISTRATION_EMAIL_TO_SEND:
            return {...state, emailToSend:action.payload}
        case SET_REGISTRATION_PHONE_TO_SEND:
            return {...state, phoneToSend:action.payload}
        case SET_REGISTRATION_PHONE_TO_RENDER:
            return {...state, phoneToRender:action.payload}
        case SET_REGISTRATION_DECAY_SECONDS:
            return {...state, decaySeconds:action.payload}
        case SET_REGISTRATION_AVAILABLE_IN:
            return {...state, availableIn:action.payload}
        case SET_REGISTRATION_TOKEN:
            return {...state, token:action.payload}
        case SET_REGISTRATION_MEMBER_ID:
            return {...state, memberId:action.payload}
        default:
            return state;
    }
}
