import { Helmet, HelmetProvider } from 'react-helmet-async';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

const DocTitleUpdate = ({children, intl}) => {
    const {formatMessage} = intl;

    return (<HelmetProvider>
        <Helmet>
            <title>{formatMessage({ id: 'SEO.METAVERSE_TITLE' })}</title>
            <meta name="description" content={formatMessage({ id: 'SEO.METAVERSE_DESC'})} />
        </Helmet>
        {children}
    </HelmetProvider>);
};

const DocTitleUpdateComponent =  injectIntl(DocTitleUpdate);

export default   connect(
    (state) => ({
        logged: !!state.member.id,
    }),
    () => ({}),
  )(injectIntl(DocTitleUpdateComponent));
