import api from "./core/api";

class WalletAddress {
    constructor(api) {
        this.api = api;
    }
    address(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/wallet-address'), options
        );
    }

    bind(address, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/wallet-address/bind'),
            {...options, json:{address}}
        );
    }
    confirm(address, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/wallet-address/confirm'),
            {...options, json:{address}}
        );
    }
}

export default (new WalletAddress(api));
