export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const SET_MEMBER = 'SET_MEMBER';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_OTP = 'SET_OTP';
export const MODE_SELECTOR_EXPAND = 'MODE_SELECTOR_EXPAND';
export const SELECT_ACTIVE_MODE = 'SELECT_ACTIVE_MODE';
export const SET_MEMBER_ID = 'SET_MEMBER_ID';
export const INIT_MAP = 'INIT_MAP';
export const SET_MQTT_CLIENT = 'SET_MQTT_CLIENT';
export const SET_MQTT_CONNECT_STATUS = 'SET_MQTT_CONNECT_STATUS';
export const SET_DISTRICTS = 'SET_DISTRICTS';
export const CHANGE_PLOT = 'CHANGE_PLOT';
export const SET_CLOSEST_EXPIRATION = 'SET_CLOSEST_EXPIRATION';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_BALANCE_ORDINARY = 'SET_BALANCE_ORDINARY';
export const SET_BALANCE_BONUS = 'SET_BALANCE_BONUS';
export const SET_BALANCE_ITW = 'SET_BALANCE_ITW';
export const SET_TICKETS_OPEN = 'SET_TICKETS_OPEN';
export const SET_TICKETS = 'SET_TICKETS';
export const SET_MEMBER_MINERALS = 'SET_MEMBER_MINERALS';
export const SET_MEMBER_WOOD = 'SET_MEMBER_WOOD';
export const SET_POINTS = 'SET_POINTS';
export const SET_EXPIRED_AT = 'SET_EXPIRED_AT';
export const SET_LANDS = 'SET_LANDS';
export const SET_LANDS_LOADING = 'SET_LANDS_LOADING';
export const SET_MINERALS = 'SET_MINERALS';
export const SET_MINERALS_LOADING = 'SET_MINERALS_LOADING';
export const SET_SHOVEL = 'SET_SHOVEL';
export const SET_SHOVEL_LOADING = 'SET_SHOVEL_LOADING';
export const SET_BUY_SHOVEL_LOADING = 'SET_BUY_SHOVEL_LOADING';
export const SET_GR_SELECTED_PLOT = 'SET_GR_SELECTED_PLOT';
export const SET_MINING = 'SET_MINING';
export const SET_AWARDS = 'SET_AWARDS';
export const SET_REDEEM_CALCULATE_LOADING = 'SET_REDEEM_CALCULATE_LOADING';
export const SET_REDEEM_LOADING = 'SET_REDEEM_LOADING';
export const SET_GOLDRUSH_MAINSTATE = 'SET_GOLDRUSH_MAINSTATE';
export const SET_MOBILE = 'SET_MOBILE';
export const SET_REGISTRATION_PHONE_TO_SEND = 'SET_REGISTRATION_PHONE_TO_SEND';
export const SET_REGISTRATION_EMAIL_TO_SEND = 'SET_REGISTRATION_EMAIL_TO_SEND';
export const SET_REGISTRATION_PHONE_COUNTRY = 'SET_REGISTRATION_PHONE_COUNTRY';
export const SET_REGISTRATION_PHONE_TO_RENDER = 'SET_REGISTRATION_PHONE_TO_RENDER';
export const SET_REGISTRATION_DECAY_SECONDS = 'SET_REGISTRATION_DECAY_SECONDS';
export const SET_REGISTRATION_AVAILABLE_IN = 'SET_REGISTRATION_AVAILABLE_IN';
export const SET_REGISTRATION_TOKEN = 'SET_REGISTRATION_TOKEN';
export const SET_REGISTRATION_MEMBER_ID = 'SET_REGISTRATION_MEMBER_ID';
export const SET_LY_STATE = 'SET_LY_STATE';
export const SET_LY_PLOTS = 'SET_LY_PLOTS';
export const SET_LY_PLOT = 'SET_LY_PLOT';
export const SET_LY_INVENTORY = 'SET_LY_INVENTORY';
export const SET_LY_MATERIALS = 'SET_LY_MATERIALS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_LY_TO_BUY = 'SET_LY_TO_BUY';
export const SET_LY_BUY_PRODUCTS_LOADING = 'SET_LY_BUY_PRODUCTS_LOADING';
export const SET_LY_FORBIDDEN_ACTIONS = 'SET_LY_FORBIDDEN_ACTIONS';
export const SET_LY_AWARD = 'SET_LY_AWARD';
export const SET_LY_SHOP_OPEN = 'SET_LY_SHOP_OPEN';
export const SET_LY_SHOP_DATA = 'SET_LY_SHOP_DATA';
export const SET_LY_PURCHASED = 'SET_LY_PURCHASED';
export const SET_LY_PLOTS_LOAD = 'SET_LY_PLOTS_LOAD';
export const SET_OPEN_SUBMENU = 'SET_OPEN_SUBMENU';
export const SET_SHOW_MODAL_ABEY_WALLET = 'SET_SHOW_MODAL_ABEY_WALLET';
export const SET_SHOW_MODAL_LIKED_WALLET = 'SET_SHOW_MODAL_LIKED_WALLET';
export const SET_SYS_WALLET_ADDRESS = 'SET_SYS_WALLET_ADDRESS';
export const SET_SYS_WALLET_BALANCE = 'SET_SYS_WALLET_BALANCE';
export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';
export const SET_WALLET_ADDRESS_STATUS = 'SET_WALLET_ADDRESS_STATUS';
export const SET_LY_PLOT_LIST_OPEN = 'SET_LY_PLOT_LIST_OPEN';
export const SET_IS_SHORT_MENU = 'SET_IS_SHORT_MENU';
export const SET_SHOW_PLOTS_MODAL = 'SET_SHOW_PLOTS_MODAL';
export const SET_SHOW_WAREHOUSE_MODAL = 'SET_SHOW_WAREHOUSE_MODAL';
export const SET_MAP_MQTT_PLOT_UPDATE_PAYLOAD = 'SET_MAP_MQTT_PLOT_UPDATE_PAYLOAD';
export const SET_MAP_REQUEST_ALREADY_SENT = 'SET_MAP_REQUEST_ALREADY_SENT';
export const SET_MAP_DISTRICT_THUMBNAILS = 'SET_MAP_DISTRICT_THUMBNAILS';
export const SET_MAP_SHOW_IMAGES = 'SET_MAP_SHOW_IMAGES';
export const SET_MAP_IS_RENDER_THUMBNAILS = 'SET_MAP_IS_RENDER_THUMBNAILS';
export const SET_MARKETPLACE_DEFAULT = 'SET_MARKETPLACE_DEFAULT';
export const SET_MARKETPLACE_SELECTED_ITEM = 'SET_MARKETPLACE_SELECTED_ITEM';
export const SET_MARKETPLACE_CONTENT_TYPE = 'SET_MARKETPLACE_CONTENT_TYPE';
export const SET_MARKETPLACE_ERROR = 'SET_MARKETPLACE_ERROR';
export const SET_MAP_BUILTBUILDINGS = 'SET_MAP_BUILTBUILDINGS';
export const ADD_MAP_BUILTBUILDINGS = 'ADD_MAP_BUILTBUILDINGS';
export const DELETE_MAP_BUILTBUILDINGS = 'DELETE_MAP_BUILTBUILDINGS';
export const SET_UNREAD_ANNOUNCEMENTS = 'SET_UNREAD_ANNOUNCEMENTS';
export const SET_UNREAD_EVENTS = 'SET_UNREAD_EVENTS';
export const ACTIVATE_PROMT = 'ACTIVATE_PROMT';
export const ADD_PROMT_ELEMENT = 'ADD_PROMT_ELEMENT';
export const SKIP_ALL_PROMT = 'SKIP_ALL_PROMT';
export const DEL_PROMT_ELEMENT = 'DEL_PROMT_ELEMENT';
export const COMPLITE_PROMT = 'COMPLITE_PROMT';
export const SET_SHOW_ERROR_MODAL = 'SET_SHOW_ERROR_MODAL';
export const SET_SHOW_CROSS_SUCCESS_MODAL = 'SET_SHOW_CROSS_SUCCESS_MODAL';
export const APPEND_ERROR = 'APPEND_ERROR';
export const APPEND_CROSS_SUCCESS = 'APPEND_CROSS_SUCCESS';
export const LOAD_NEXT_TABLE_PAGE = 'LOAD_NEXT_TABLE_PAGE';
export const CHANGE_ERROR_LIST = 'CHANGE_ERROR_LIST';
export const CHANGE_CROSS_SUCCESS_LIST = 'CHANGE_CROSS_SUCCESS_LIST';
export const RETURN_TO_TABLE_PAGE = 'RETURN_TO_TABLE_PAGE';
export const SET_MEMBER_IS_HAVE_PLOTS = "SET_MEMBER_IS_HAVE_PLOTS";
export const SET_MEMBER_ADNET_IS_VISIBLE = "SET_MEMBER_ADNET_IS_VISIBLE";
export const SET_2D_ACCESS = "SET_2D_ACCESS";

export const setLoginProcessGo = (isGo) => ({
    type: LOGIN_PROCESS,
    payload:isGo,
});
export const setMemberData = (memberData) => {
    localStorage.setItem('member', JSON.stringify(memberData));
    return {
        type: SET_MEMBER,
        payload:memberData,
    };
};
export const setOtp = (otp) => ({
    type: SET_OTP,
    payload:otp
});
export const setAccessToken = (accessToken) => {
    localStorage.setItem('accessToken', accessToken);
    return {
        type: SET_ACCESS_TOKEN,
        payload:accessToken
    }
};
export const selectActiveMode = (mode) => ({
    type: SELECT_ACTIVE_MODE,
    payload: mode,
});
export const expandModeSelector = (expanded) => ({
    type: MODE_SELECTOR_EXPAND,
    payload: expanded,
});
export const setMemberId = (memberId) => ({
    type:SET_MEMBER_ID,
    payload: memberId,
});
export const initMap = payload => ({
    type:INIT_MAP,
    payload,
});
export const setDistricts = payload => ({
    type:SET_DISTRICTS,
    payload,
});
export const changePlot = payload => ({
    type:CHANGE_PLOT,
    payload
});
export const setClosestExpiration = payload => ({
    type:SET_CLOSEST_EXPIRATION,
    payload
});
export const setBalance = payload => ({
    type:SET_BALANCE,
    payload
});
export const setBalanceOrdinary = payload => ({
    type:SET_BALANCE_ORDINARY,
    payload
});
export const setBalanceBonus = payload => ({
    type:SET_BALANCE_BONUS,
    payload
});
export const setBalanceItw = payload => ({
    type:SET_BALANCE_ITW,
    payload
});
export const setPoints = payload => ({
    type:SET_POINTS,
    payload
});
export const setTicketsOpen = payload => ({
    type:SET_TICKETS_OPEN,
    payload
})
export const setTickets = payload => ({
    type:SET_TICKETS,
    payload
});
export const setMemberWood= payload => ({
    type:SET_MEMBER_WOOD,
    payload
});
export const setMemberMinerals= payload => ({
    type:SET_MEMBER_MINERALS,
    payload
});
export const setExpiredAt = payload => {
    localStorage.setItem('expiredAt', payload.getTime());
    return {
        type:SET_EXPIRED_AT,
        payload
    }
};
export const setGoldRushLands = payload => ({
    type: SET_LANDS,
    payload
});
export const setLandsLoading = payload => ({
    type: SET_LANDS_LOADING,
    payload
});
export const setGoldRushMinerals = payload => ({
    type: SET_MINERALS,
    payload
});
export const setGoldRushMineralsLoading = payload => ({
    type: SET_MINERALS_LOADING,
    payload
});
export const setShovel = payload => ({
    type: SET_SHOVEL,
    payload
});
export const setShovelLoading = payload => ({
    type: SET_SHOVEL_LOADING,
    payload
});
export const setBuyShovelLoading = payload => ({
    type: SET_BUY_SHOVEL_LOADING,
    payload
});
export const setMining = payload => ({
    type: SET_MINING,
    payload
});
export const setAwards = payload => ({
    type: SET_AWARDS,
    payload
});
export const setRedeemLoading = payload => ({
    type: SET_REDEEM_LOADING,
    payload
});
export const setRedeemCalculateLoading = payload => ({
    type: SET_REDEEM_CALCULATE_LOADING,
    payload
});
export const setGoldrushMainstate = payload => ({
    type: SET_GOLDRUSH_MAINSTATE,
    payload
});
export const setMobile = (payload) => ({
    type: SET_MOBILE,
    payload,
});
export const setRegistrationPhoneToRender = (payload) => ({
    type: SET_REGISTRATION_PHONE_TO_RENDER,
    payload
});
export const setRegistrationPhoneToSend = (payload) => ({
    type: SET_REGISTRATION_PHONE_TO_SEND,
    payload
});
export const setRegistrationEmailToSend = (payload) => ({
    type: SET_REGISTRATION_EMAIL_TO_SEND,
    payload
});
export const setRegistrationDecaySeconds = (payload) => ({
    type: SET_REGISTRATION_DECAY_SECONDS,
    payload
});
export const setRegistrationAvailableIn = (payload) => ({
    type: SET_REGISTRATION_AVAILABLE_IN,
    payload
});
export const setRegistrationPhoneCountry = (payload) => ({
    type: SET_REGISTRATION_PHONE_COUNTRY,
    payload
});
export const setRegistrationToken = (payload) => ({
    type: SET_REGISTRATION_TOKEN,
    payload
});
export const setRegistrationMemberId = (payload) => ({
    type: SET_REGISTRATION_MEMBER_ID,
    payload
});
export const setLyState = payload => ({
    type: SET_LY_STATE,
    payload
});
export const setLyToBuy = payload => ({
    type: SET_LY_TO_BUY,
    payload
});
export const setLyPlots = payload => ({
    type: SET_LY_PLOTS,
    payload
});
export const setLyPlot = payload => ({
    type: SET_LY_PLOT,
    payload
});
export const setLyInventory = payload => ({
    type: SET_LY_INVENTORY,
    payload
});
export const setLyPlotsLoad = payload => ({
    type: SET_LY_PLOTS_LOAD,
    payload
});
export const setProducts = payload => ({
    type: SET_PRODUCTS,
    payload
});
export const setLyBuyProductsLoading = payload => ({
    type: SET_LY_BUY_PRODUCTS_LOADING,
    payload
});
export const setLyMaterials = payload => ({
    type: SET_LY_MATERIALS,
    payload
});
export const setLyForbiddenActions = payload => ({
    type: SET_LY_FORBIDDEN_ACTIONS,
    payload
});
export const setLyAward = payload => ({
    type: SET_LY_AWARD,
    payload
});
export const setLyShopOpen = payload => ({
    type: SET_LY_SHOP_OPEN,
    payload
});
export const setLyPurchased = payload => ({
    type: SET_LY_PURCHASED,
    payload
});
export const setOpenSubmenu = payload => ({
    type: SET_OPEN_SUBMENU,
    payload
});
export const setShowModalLinkedWalet = payload => ({
    type: SET_SHOW_MODAL_LIKED_WALLET,
    payload
});
export const setShowModalAbeyWallet = payload => ({
    type: SET_SHOW_MODAL_ABEY_WALLET,
    payload
});
export const setSysWalletAddress = payload => {
    const currentMember = JSON.parse(localStorage.getItem('member'));
    localStorage.setItem('member', JSON.stringify({...currentMember, sysWalletAddress:payload}));
    return ({
        type: SET_SYS_WALLET_ADDRESS,
        payload
    });
};
export const setSysWalletBalance = payload => {
    const currentMember = JSON.parse(localStorage.getItem('member'));
    localStorage.setItem('member', JSON.stringify({...currentMember, sysWalletBalance:payload}));
    return ({
        type: SET_SYS_WALLET_BALANCE,
        payload
    });
};
export const setWalletAddress = payload => {
    const currentMember = JSON.parse(localStorage.getItem('member'));
    localStorage.setItem('member', JSON.stringify({...currentMember, address:payload}));
    return ({
        type: SET_WALLET_ADDRESS,
        payload
    });
};
export const setWalletAddressStatus = payload => {
    const currentMember = JSON.parse(localStorage.getItem('member'));
    localStorage.setItem('member', JSON.stringify({...currentMember, addressStatus:payload}));
    return ({
        type: SET_WALLET_ADDRESS_STATUS,
        payload
    });
};
export const setLyPlotListOpen = payload => ({
    type: SET_LY_PLOT_LIST_OPEN,
    payload
});
export const setLyShopData = payload => ({
    type: SET_LY_SHOP_DATA,
    payload
});
export const setIsShortMenu = payload => ({
    type: SET_IS_SHORT_MENU,
    payload
});

export const setGRSelectedPlot = payload => ({
    type: SET_GR_SELECTED_PLOT,
    payload
});
export const setShowPlotsModal = payload => ({
    type: SET_SHOW_PLOTS_MODAL,
    payload
});
export const setShowWarehouseModal = payload => ({
    type: SET_SHOW_WAREHOUSE_MODAL,
    payload
});
export const setMqttClient = payload => ({
    type: SET_MQTT_CLIENT,
    payload
})
export const setMqttConnectStatus = payload => ({
    type: SET_MQTT_CONNECT_STATUS,
    payload
})
export const setMapMqttPlotUpdatePayload = payload => ({
    type: SET_MAP_MQTT_PLOT_UPDATE_PAYLOAD,
    payload
});
export const setMapRequestAlreadySent = payload => ({
    type: SET_MAP_REQUEST_ALREADY_SENT,
    payload
});
export const setMapDistrictThumbnails = payload  => ({
    type:SET_MAP_DISTRICT_THUMBNAILS,
    payload
});
export const setMapIsRenderThumbnails = payload  => ({
    type:SET_MAP_IS_RENDER_THUMBNAILS,
    payload
});
export const setMapShowImages = payload => {
    localStorage.setItem('mapShowImages', payload ? 1:0);
    return {
        type: SET_MAP_SHOW_IMAGES,
        payload
    }
};
export const setMarketplaceSelectedItem = payload => ({
    type: SET_MARKETPLACE_SELECTED_ITEM,
    payload
});
export const setMarketplaceContentType = payload => ({
    type: SET_MARKETPLACE_CONTENT_TYPE,
    payload
});
export const setMarketplaceDefault = payload => ({
    type: SET_MARKETPLACE_DEFAULT,
    payload
});
export const setMarketplaceError = payload => ({
    type: SET_MARKETPLACE_ERROR,
    payload
});
export const setMapBuiltBuildings = payload => ({
    type: SET_MAP_BUILTBUILDINGS,
    payload
});
export const addMapBuiltBuildings = payload => ({
    type: ADD_MAP_BUILTBUILDINGS,
    payload
});
export const deleteMapBuiltBuildings = payload => ({
    type: DELETE_MAP_BUILTBUILDINGS,
    payload
});
export const setUnreadAnnouncements = payload => ({
    type: SET_UNREAD_ANNOUNCEMENTS,
    payload
});
export const setUnreadEvents = payload => ({
    type: SET_UNREAD_EVENTS,
    payload
});
export const addPromtElement = payload => ({
    type: ADD_PROMT_ELEMENT,
    payload
});
export const delPromtElement = payload => ({
    type: DEL_PROMT_ELEMENT,
    payload
});
export const activatePromt = payload => {
    return ({
        type: ACTIVATE_PROMT,
        payload
    });
};
export const skipAllPromt = payload => {
    return ({
        type: SKIP_ALL_PROMT,
        payload
    });
};
export const complitePromt = payload => {
    const currentPromtState = JSON.parse(localStorage.getItem('promtState'));
    localStorage.setItem('promtState', JSON.stringify({...currentPromtState, [payload]: true}));
    return ({
        type: COMPLITE_PROMT,
        payload
    })
};
export const setShowErrorModal = payload => ({
    type: SET_SHOW_ERROR_MODAL,
    payload,
});
export const setShowSuccessModal = payload => ({
    type: SET_SHOW_CROSS_SUCCESS_MODAL,
    payload,
});
export const appendError = payload => ({
    type: APPEND_ERROR,
    payload
});
export const appendCrossSuccess = payload => ({
    type: APPEND_CROSS_SUCCESS,
    payload
});
export const changeErrorList = payload => ({
    type: CHANGE_ERROR_LIST,
    payload
});

export const changeCrossSuccessList = payload => ({
    type: CHANGE_CROSS_SUCCESS_LIST,
    payload
});

export const loadNextTablePage = payload => ({
    type:LOAD_NEXT_TABLE_PAGE,
    payload,
});
export const returnToTablePage = payload => ({
    type:RETURN_TO_TABLE_PAGE,
    payload,
});
export const setMemberIsHavePlots = payload => ({
    type:SET_MEMBER_IS_HAVE_PLOTS,
    payload
});
export const setMemberAdnetIsVisible = payload => ({
    type:SET_MEMBER_ADNET_IS_VISIBLE,
    payload
});
export const set2DAccess = payload => ({
    type:SET_2D_ACCESS,
    payload
});
